
export enum DialogResult {
  OK = 'OK',
  Cancel = 'Cancel'
}

export interface DialogResponse {
  dialogResult: DialogResult,
  data?: any,
}
