import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectProps, TextField } from "@mui/material";
import { FieldAttributes, Form, Formik, useField } from "formik";
import { FMTextField } from "../Elements/input";
import React from "react";
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface br18Data_resp {
    e24GrossPremiumsCharged: string;
    e25AmountOfReturnedPremiums: string;
    a33Attestation: string;
    b33DateInFormat: string;
    d33SpecialBrokerName: string;
    b36BusinessAddress: string;
    b37TelephoneNumber: string;
    b38BrokerLicenseNumber: string;
    b39AuthorizedOfficer: string;
    b40EmailAddress: string;
    b41PreparersName: string;
    b42PreparersTelephone: string;
    b43PreparersEMailAddress: string;
  }

interface Iprops {
    handleClose: () => void,
    Open: boolean,
    br18Data: br18Data_resp
}
interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
            />
        );
    },
);

export const Br18Form: React.FC<Iprops> = ({ handleClose, Open, br18Data}) => {
    const initialValues: br18Data_resp = br18Data;
    return (

        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle className='card-title' >BR18 Form</DialogTitle>
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={(data, { setSubmitting }) => {
                    
                }} >
                {({ values, isSubmitting, setFieldValue, errors }) => (
                    <Form className='main-form' >
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="a33Attestation" autoFocus margin="dense" label="Attestation" placeholder="Attestation" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <FMTextField
                                            name="d33SpecialBrokerName"
                                            autoFocus
                                            margin="dense"
                                            label="Special Broker Name"
                                            placeholder="Special Broker Name"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ m: 2, width: '100%' }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="b36BusinessAddress" autoFocus margin="dense" label="Business Address" placeholder="Business Address" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="b37TelephoneNumber" autoFocus margin="dense" label="Telephone Number" placeholder="Telephone Number" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="b38BrokerLicenseNumber" autoFocus margin="dense" label="Broker License Number" placeholder="Broker License Number" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="b39AuthorizedOfficer" autoFocus margin="dense" label="Authorized Officer" placeholder="Authorized Officer" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="b40EmailAddress" autoFocus margin="dense" label="Email Address" placeholder="Email Address" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="b41PreparersName" autoFocus margin="dense"  label="Preparers Name" placeholder="Preparers Name" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="b42PreparersTelephone" autoFocus margin="dense"  label="Preparers Telephone" placeholder="Preparers Telephone" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="b43PreparersEMailAddress" autoFocus margin="dense"  label="Preparers Email Address" placeholder="Preparers Email Address" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <FMTextField
                                            name="e24GrossPremiumsCharged"
                                            label="Gross Premiums Charged"
                                            placeholder="Gross Premiums Charged"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ m: 2, width: '100%' }}
                                            InputProps={{
                                                inputComponent: NumericFormatCustom as any,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <FMTextField
                                            name="e25AmountOfReturnedPremiums"
                                            label="Amount Of Returned Premiums"
                                            placeholder="Amount Of Returned Premiums"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ m: 2, width: '100%' }}
                                            InputProps={{
                                                inputComponent: NumericFormatCustom as any,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn" type='button' onClick={handleClose}  >Cancel</button>
                            <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog >
    )
}
