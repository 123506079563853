
import * as yup from 'yup'

export interface SurplusLineFormMapping {

    surplusLineFormId?: number,
    state?: string,
    stateId? :number,
    butlerModel?: string,
    modelId?: number,
    transactionName?: string,
    commaSeparatedTransactionIds?: string,
    documentName?: string,
    documentTypeId?: number,
    transactionId?: number[],
    isRequired?:boolean,   
    
  
}

export const SurplusLineFormMapping_VS = yup.object({
    transactionId: yup.number().required("Please select Transaction Type ").typeError("invalid value"),
    documentTypeId: yup.number().required("Please select Document Type").min(1, "Please select Document Type").typeError("invalid value"),
    // modelId: yup.number().min(1,"Please select model").required("Please select model ").typeError("invalid value"),
   //documentName: yup.string().required("Please select Transaction Type")
//    commaSeparatedTransactionIds : yup.string().required("Please select Transaction Type"),
  
 
  })


