import React from "react";
import { useNavigate } from "react-router-dom";

const MessagesList = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/"); // Redirect back to another route, e.g., ButlerModel
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2 style={{ color: "#00A9E0" }}>All Messages</h2>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {/* Example Messages */}
        <li style={{ borderBottom: "1px solid #ddd", padding: "10px 0" }}>
          <strong>Customer 1:</strong> This is your first message.
          <br />
          <small style={{ color: "#888" }}>1/16/2025 10:30 PM</small>
        </li>
        <li style={{ borderBottom: "1px solid #ddd", padding: "10px 0" }}>
          <strong>Customer 2:</strong> Thanks for the update!
          <br />
          <small style={{ color: "#888" }}>1/16/2025 10:45 PM</small>
        </li>
      </ul>
      <button
        onClick={handleClose}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "#1976D2",
          color: "#fff",
          border: "none",
          cursor: "pointer",
          borderRadius: "4px",
          fontWeight: "bold",
        }}
      >
        Close
      </button>
    </div>
  );
};

export default MessagesList;
