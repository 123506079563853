import React, { useState } from "react";
import { Route, RouterProvider, Routes, useRoutes } from "react-router-dom";
import { AddCustomerInvoice } from "./components/AddEdit/AddCustomerInvoice";
import { AddEditCustomerPaymentLinks } from "./components/AddEdit/AddEditCustomerPaymentLink";
import { AddEditEmailTemplate } from "./components/AddEdit/AddEditEmailTemplate";
import { AddEditPartners } from "./components/AddEdit/AddEditPartners";
import { AddEditStateStamps } from "./components/AddEdit/AddEditStateStamps";
import useAuth, { AuthProvider } from "./context/AuthProvider";
import { PrivateRoute } from "./context/ProtectedRoute";
import { TransactionDetails } from "./DetailPages/TransactionDetails";
import { ButlerModel } from "./Pages/ButlerModel";
import { ButlerModelDocument } from "./Pages/ButlerModelDocument";
import { ComingSoon } from "./Pages/ComingSoon";
import { CreateCustomerPolicy } from "./Pages/CreateCustomerPolicy";
import { CustomerInvoice } from "./Pages/CustomerInvoice";
import { CustomerPolicy } from "./Pages/CustomerPolicy";
import { CustomerPolicyDocumentData } from "./Pages/CustomerPolicyDocumentData";
import { Submissions } from "./Pages/Submissions";
import { EmailTemplate } from "./Pages/EmailTemplate";
import { FMFlagReview } from "./Pages/FMFlagReview";
import { InsuranceComapny } from "./Pages/InsuranceCompany";
import { Insureds } from "./Pages/Insureds";
import { Lineofbusiness } from "./Pages/LineOfBusiness";
import { LoginPage } from "./Pages/Login";
import { CreateNewSubmission } from "./Pages/NewSubmission";
import { Partners } from "./Pages/Partners";
import { PartnerUsers } from "./Pages/PartnerUsers";
import { Policies } from "./Pages/Policies";
import { Policy } from "./Pages/Policy";
import { PolicyFilingsSchedule } from "./Pages/PolicyFilingSchedule";
import { ReportModule } from "./Pages/ReportModule";
import { ResubmitCustomerPolicyDocs } from "./Pages/ResubmitCustomerPolicyDocs";
import { StateRuleMatrix } from "./Pages/StateRuleMatrix";
import { StateStampList } from "./Pages/StateStampList";
import { StatesZeroFilingRule } from "./Pages/StatesZeroFilingsRule";
import { SurplusLineForms } from "./Pages/SurplusLineForms";
import { TaxesAndFees } from "./Pages/TaxesAndFees";
import { TaxesAndFeesFliingSchedule } from "./Pages/TaxesAndFeesFilingSchedule";
import { Trainer } from "./Pages/Trainer";
import { Transaction } from "./Pages/Transaction";
import { TransactionFee } from "./Pages/TransactionFee";
import { TransactionFeePartner } from "./Pages/TransactionFeePartner";
import { TransactionType } from "./Pages/TransactionType";
import { Users } from "./Pages/Users";
import { InsuredSubmissions } from "./Pages/InsuredSubmissions";
import { Logs } from "./Pages/Logs";
import { Payables } from "./Pages/Payables";
import { PolicyDashboard } from "./Pages/PolicyDashboard";
import { DocumentCorrections } from "./Pages/DocumentCorrections";
import { AdminDashboard } from "./Pages/AdminDashboard";
import { ClientDashboard } from "./Pages/ClientDashboard";
import { CarrierManagement } from "./Pages/CarrierManagement";
import { ResubmitSingleDocumentNew } from "./Pages/ResubmitsingleDocumentNew";
import { AddButlerModels } from "./components/AddEdit/AddButlerModel";
import { ButlerMapping } from "./Pages/ButlerMappings";
import { DocumentTypeCustom } from "./Pages/DocumentType";
import { AddEditCASlaJsonData } from "./components/AddEdit/AddEditCASLaJsonData";
import { AddUserMappingRequestLog } from "./Pages/UserMappingRequestLog";
import { PageNotFound } from "./Pages/PageNotFound";
import { SurplusLineFormMapping } from "./Pages/SurplusLineFormMapping";
import { ResetPassword } from "./Pages/ResetPassword";
import { Thankyou } from "./Pages/ThankYouSuccessMsg";
import { BtisApiData } from "./Pages/BtisAPIData";
import { AddStateFlags } from "./Pages/AddStateFlags";
import { TaxScheduleByJurisdiction } from "./Pages/TaxScheduleByJurisdiction";
import { SubmissionData_SD } from "./Pages/SubmissionData_SD";
import { LateFilingForm } from "./Pages/LateFilingReason";
import { AddEditCarrierManageLineOfBussinessCheckBox } from "./components/AddEdit/AddEditCarrierManageLineOfBussinessCheckBox";
import { EmailReportModule } from "./Pages/EmailReportModule";
import { EmailReportModuleDetails } from "./Pages/EmailReportModuleDetails";
import { RightNav } from "./components/Nav/RightNav";
// LateFilingRequest
import type { RouteObject } from "react-router";
import Layout from "./components/Layout/Layout";
import CustomerLayout from "./components/Layout/CustomerLayout";
import "./App.css"
import { OpenTaxReport } from "./Pages/OpenTaxReport";
import { ReconciliationQueue } from "./Pages/ReconciliationQueue";
import { DiligentSearch } from "./Pages/DiligentSearch";
import { ClientBillingReport } from "./Pages/ClientBillingReport";
import { StateReportsBilling} from "./Pages/StateReportsBilling"
import { AddEditCustomerPolicyDetails } from "./components/AddEdit/AddEditCustomerPolicyDetails";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { StateLogins } from "./Pages/StateLogins";
import { MonthEndBillingModule } from "./Pages/MonthEndBillingModule";
import { StateReport } from "./Pages/StateReport";
import { RemovedQueue } from "./Pages/PolicyDashboardElements/RemovedQueue";
import { CustomerTransactions } from "./CustomerPanel/CustomerTransactions";
import MessagesList from "./components/AddEdit/MessageList";
import AppDialogs from "./components/Dialog/AppDialogs";

// import " /assets/dist/css/proximanova_regular.ttf"

LicenseInfo.setLicenseKey('b4c0ff2e8469028df65cac8c87e8b99cTz05MzUxOSxFPTE3NTE0Nzk1NzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')


function App() {
  const { user, loading } = useAuth();
console.log(window.location.pathname.includes("Demo"));
  const routes: RouteObject[] = [
    {
      // parent route component
      element: user?.userRoles.split(",").includes("Customer User") ? <CustomerLayout /> : <Layout />,
      // child route components
      children: [
        {
          path: "/Customer",
          element: (
            <PrivateRoute roles={['Customer User', 'Customer Admin']} currentUser={user} loading={loading}>
                <CustomerTransactions currentUser={user}/>
            </PrivateRoute>
          ),
        },
        {
          path: "/",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              {user?.userRoles.split(",").includes("Super Admin") ||
              user?.userRoles.split(",").includes("MISC Filer") ? (
                <PolicyDashboard />
              ) : (
                <PolicyDashboard />
              )}
            </PrivateRoute>
          ),
        },

        {
          path: "/:LastTabId",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              {user?.userRoles.split(",").includes("Super Admin") ||
              user?.userRoles.split(",").includes("MISC Filer") ? (
                <PolicyDashboard />
              ) : (
                <PolicyDashboard />
              )}
            </PrivateRoute>
          ),
        },

        {
          path: "/FillingQueue/RemovedQueue",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
                <RemovedQueue />
            </PrivateRoute>
          ),
        },

        {
          path: "/ReconciliationQueue/RemovedQueue",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
                <RemovedQueue />
            </PrivateRoute>
          ),
        },

        {
          path: "/Policies",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <Policies />
            </PrivateRoute>
          ),
        },
        {
          path: "/Submissions",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <Submissions />
            </PrivateRoute>
          ),
        },
        {
          path: "/Policy/:batchId",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <Policy />
            </PrivateRoute>
          ),
        },


        {
          path: "/Transaction/:policyid/:customerpolicyid",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <Transaction />
            </PrivateRoute>
          ),
        },

        // {
        //   path: "/Transaction/:policyid",
        //   element: (
        //     <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
        //       <Transaction />
        //     </PrivateRoute>
        //   ),
        // },
        {
          path: "/Admin/TaxesAndFees",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <TaxesAndFees />
            </PrivateRoute>
          ),
        },

        {
          path: "/Admin/LineOfBusiness",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <Lineofbusiness />
            </PrivateRoute>
          ),
        },
        {
          path: "/Admin/InsuranceCompany",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <InsuranceComapny />
            </PrivateRoute>
          ),
        },

        {
          path: "/Admin/Users",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <Users />
            </PrivateRoute>
          ),
        },
        {
          path: "/Admin/TransactionType",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <TransactionType />
            </PrivateRoute>
          ),
        },
     

        {
          path: "/Document/:transactionID",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <TransactionDetails />
            </PrivateRoute>
          ),
        },
        {
          path: "/CreateCustomerPolicy/:CustomerPolicyId",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <CreateCustomerPolicy />
            </PrivateRoute>
          ),
        },

        {
          path: "/NewSubmission",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <CreateNewSubmission />
            </PrivateRoute>
          ),
        },
        {
          path: "/CustomerPolicy",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <CustomerPolicy />
            </PrivateRoute>
          ),
        },
        {
          path: "/Admin/StateStampList",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <StateStampList />
            </PrivateRoute>
          ),
        },
        {
          path: "/AddEditStateStamps/:statesStampsListsId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <AddEditStateStamps />
            </PrivateRoute>
          ),
        },

        {
          path: "/StateLogins",
          element: (
            <PrivateRoute
              roles={["MISC Filer", "MISC State Reporter", "Super Admin"]}
              currentUser={user}
              loading={loading}
            >
              <StateLogins />
            </PrivateRoute>
          ),
        },

        {
          path: "/Admin/StateRuleMatrix",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <StateRuleMatrix />
            </PrivateRoute>
          ),
        },

        {
          path: "/Admin/StatesZeroFilingRule",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <StatesZeroFilingRule />
            </PrivateRoute>
          ),
        },
        {
          path: "/CustomerPolicyDocumentData/:custPolicyId",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <CustomerPolicyDocumentData />
            </PrivateRoute>
          ),
        },
        {
          path: "/FMFlagReview/:type",
          element: (
            <PrivateRoute
              roles={["Super Admin", "Outsource Staff", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <FMFlagReview />
            </PrivateRoute>
          ),
        },
        {
          path: "/Partners",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer", "Customer Admin"]}
              currentUser={user}
              loading={loading}
            >
              <Partners />
            </PrivateRoute>
          ),
        },

        {
          path: "/UserPartners/:partnersId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <PartnerUsers />
            </PrivateRoute>
          ),
        },
        {
          path: "/Insureds",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <Insureds />
            </PrivateRoute>
          ),
        },

        {
          path: "/ButlerModelList",
          element: (
            <PrivateRoute
              roles={["Super Admin", "Outsource Staff", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <ButlerModel />
            </PrivateRoute>
          ),
        },
        {
          path: "/ButlerModelDocument/:modelId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <ButlerModelDocument />
            </PrivateRoute>
          ),
        },

        {
          path: "/Train/:butlerModelDocumentId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "Outsource Staff", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <Trainer />
            </PrivateRoute>
          ),
        },
        {
          path: "/Admin/ManualStateSubmissionSchedule",
          element: (
            <PrivateRoute
              roles={["Super Admin", "Outsource Staff", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <PolicyFilingsSchedule />
            </PrivateRoute>
          ),
        },

        { path: "/ThankYouSuccessMsg/", element: <Thankyou /> },

        {
          path: "/Admin/Filing-Schedule/:type",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <TaxesAndFeesFliingSchedule />
            </PrivateRoute>
          ),
        },

        {
          path: "/AddEditPartners/:partnersId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer", "Customer Admin"]}
              currentUser={user}
              loading={loading}
            >
              <AddEditPartners />
            </PrivateRoute>
          ),
        },

        {
          path: "/TransactionFee",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <TransactionFee />
            </PrivateRoute>
          ),
        },

        {
          path: "/AddCustomerInvoice",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <AddCustomerInvoice />
            </PrivateRoute>
          ),
        },

        {
          path: "/CustomerInvoice",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <CustomerInvoice />
            </PrivateRoute>
          ),
        },

        {
          path: "/TransactionFeePartner",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <TransactionFeePartner />
            </PrivateRoute>
          ),
        },

        {
          path: "/EmailTemplates",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <EmailTemplate />
            </PrivateRoute>
          ),
        },

        {
          path: "/AddEditEmailTemplate/:emailTemplateId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <AddEditEmailTemplate />
            </PrivateRoute>
          ),
        },

        {
          path: "/SurplusLineForms",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <SurplusLineFormMapping />
            </PrivateRoute>
          ),
        },

        {
          path: "/AddEditCustomerPaymentLinks/:customerPaymentLinkId",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <AddEditCustomerPaymentLinks />
            </PrivateRoute>
          ),
        },

        {
          path: "/ResubmitCustomerPolicyDocs/:CustomerPolicyId",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <ResubmitCustomerPolicyDocs />
            </PrivateRoute>
          ),
        },

        {
          path: "/Admin/ReportModule",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <ReportModule />
            </PrivateRoute>
          ),
        },

        {
          path: "/ReconciliationQueue",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <ReconciliationQueue />
            </PrivateRoute>
          ),
        },
        {
          path: "/ReconciliationQueue/:LastTabId",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <ReconciliationQueue />
            </PrivateRoute>
          ),
        },
        {
          path: "/Reporting/DiligentSearch",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <DiligentSearch />
            </PrivateRoute>
          ),
        },
        {
          path: "/Reporting/StateReport",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <StateReport/>
            </PrivateRoute>
          ),
        },
        {
          path: "/Billing/MonthEndBillingModule",
          element: (
            <PrivateRoute roles={["Super Admin", "MISC Filer"]} currentUser={user} loading={loading}>
              <MonthEndBillingModule/>
            </PrivateRoute>
          ),
        },

        {
          path: "/InsuredSubmissions/:insuredId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <InsuredSubmissions />
            </PrivateRoute>
          ),
        },

        {
          path: "/Logs",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <Logs />
            </PrivateRoute>
          ),
        },

        {
          path: "/Paymule/Payables",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <Payables />
            </PrivateRoute>
          ),
        },

        {
          path: "/AdminDashboard",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <AdminDashboard />
            </PrivateRoute>
          ),
        },

        {
          path: "/PolicyDashboard/:LastTabId",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <PolicyDashboard />
            </PrivateRoute>
          ),
        },
        {
          path: "/PolicyDashboard/",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <PolicyDashboard />
            </PrivateRoute>
          ),
        },

        {
          path: "/CorrectedDocumentResubmission/:customerPolicyId/:documentId/:filedName/:fieldValue",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <DocumentCorrections />
            </PrivateRoute>
          ),
        },
        {
          path: "/CorrectedDocumentResubmission/:customerPolicyId/:documentId/:filedName",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <DocumentCorrections />
            </PrivateRoute>
          ),
        },

        {
          path: "/ClientDashboard",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <ClientDashboard />
            </PrivateRoute>
          ),
        },
        {
          path: "/Admin/CarrierManagement",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <CarrierManagement />
            </PrivateRoute>
          ),
        },
        {
          path: "/Admin/ButlerModel",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <ButlerModel />
            </PrivateRoute>
          ),
        },
        {
          path: "/Admin/TaxScheduleByJurisdiction",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <TaxScheduleByJurisdiction />
            </PrivateRoute>
          ),
        },
        
        {
          path: "/Admin/ButlerMappings",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <ButlerMapping />
            </PrivateRoute>
          ),
        },

        {
          path: "/Admin/DocumentType",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <DocumentTypeCustom />
            </PrivateRoute>
          ),
        },

        {
          path: "/Admin/EditCASLaSubmissionData/:CustomerPolicyId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <AddEditCASlaJsonData />
            </PrivateRoute>
          ),
        },
        {
          path: "/Admin/AddEditCustomerPolicyDetails/:CustomerPolicyId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer",  "MISC State Reporter"]}
              currentUser={user}
              loading={loading}
            >
              <AddEditCustomerPolicyDetails />
            </PrivateRoute>
          ),
        },
        {
          path: "/Admin/SubmissionData",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <SubmissionData_SD />
            </PrivateRoute>
          ),
        },

        {
          path: "/UserMappingRequestLog/",
          element: (
            <PrivateRoute roles={["MISC Filer", "MISC State Reporter", "Super Admin"]} currentUser={user} loading={loading}>
              <AddUserMappingRequestLog />
            </PrivateRoute>
          ),
        },

        {
          path: "/AutoSubmissionData/:CustomerPolicyId",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <BtisApiData />
            </PrivateRoute>
          ),
        },

        { path: "/ResetPassword/:UserHash", element: <ResetPassword /> },
        { path: "/LateFilingForm/:UserHash", element: <LateFilingForm /> },

        {
          path: "/CorrectedDocumentResubmission/:CustomerPolicyId",
          element: <ResubmitSingleDocumentNew />,
        },

        {
          path: "/Admin/AddStateFlags",
          element: (
            <PrivateRoute
              roles={["Super Admin", "MISC Filer"]}
              currentUser={user}
              loading={loading}
            >
              <AddStateFlags />
            </PrivateRoute>
          ),
        },

        { path: "*", element: <PageNotFound /> },

        {
          path: "/Admin/ReportModule",
          element: (
            <PrivateRoute roles={["Super Admin", "MISC Filer"]} currentUser={user} loading={loading}>
              <ReportModule />
            </PrivateRoute>
          ),
        },

        {
          path: "/Reporting/OpenTaxReport",
          element: (
            <PrivateRoute roles={["Super Admin", "MISC Filer"]} currentUser={user} loading={loading}>
              <OpenTaxReport />
            </PrivateRoute>
          ),
        },
        {
          path: "/Billing/FiledTransactions",
          element: (
            <PrivateRoute roles={["Super Admin", "MISC Filer"]} currentUser={user} loading={loading}>
              <ClientBillingReport />
            </PrivateRoute>
          ),
        },
        {
          path: "/Billing/StateReportsBilling",
          element: (
            <PrivateRoute roles={["Super Admin", "MISC Filer"]} currentUser={user} loading={loading}>
              <StateReportsBilling />
            </PrivateRoute>
          ),
        },
        {
          path: "/Reporting/EmailReportModule",
          element: (
            <PrivateRoute roles={["Super Admin", "MISC Filer"]} currentUser={user} loading={loading}>
              <EmailReportModule />
            </PrivateRoute>
          ),
        },
        {
          path: "/Messages",
          element: (
            <PrivateRoute roles={["Super Admin", "MISC Filer"]} currentUser={user} loading={loading}>
              <MessagesList/>
            </PrivateRoute>
          ),
        },

        {
          path: "/Reporting/EmailReportModuleDetails/:emailReportId",
          element: (
            <PrivateRoute roles={["Super Admin", "MISC Filer"]} currentUser={user} loading={loading}>
              <EmailReportModuleDetails />
            </PrivateRoute>
          ),
        },
      ],
    },
  
    { path: "/Login", element: <LoginPage /> },

    { path: "/ComingSoon", element: <ComingSoon /> },

    // { path: "/Customer/Login", element: <CustomerLoginPage /> },

  ];

  const content = useRoutes(routes);
  return (
    <>
      <AppDialogs />
      {content}
    </>);
}

export default App;
