import { Badge, Link, Tooltip } from "@mui/material";
import  { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import PopUp from "../pop-up/PopUp";
import { apiClient } from "../../helper/api"; 

export interface Notification {
  customerNotificationId: number; // Unique ID for the notification
  notificationFrom: string; // ID of the sender
  docType: number; // Type of the document (enum or number)
  notificationFromUsername: string; // Username of the sender
  notificationTo: string; // ID of the receiver
  notificationToUsername: string; // Username of the receiver
  customerPolicyDocumentId: number | null; // Nullable policy document ID
  documentName: string | null; // Nullable document name
  documentPath: string | null; // Nullable document path (URL)
  dateCreated: string; // ISO format string for creation date
  dateModified: string; // ISO format string for modification date
}


export enum NotificationDocumentType {
  Uploaded = 0,
  Reverted = 1,
  Messaged = 2,
}

const Notification = () => {
  // ***************************************    Pop Up *************************************************

  const [show, setShow] = useState(false);
  const [singleItem, setSingleItem] = useState<Notification | null>(null);

  const handleClose = () => setShow(false);
  const handleShow = (item:any) => { setSingleItem(item);setShow(true);}

  //  **************************************** END Pop Up *************************************************



  //  **************************************** API CALL *************************************************

   const [notificationList,setNotificationList]=useState<Notification[] | []>([])


   useEffect(() => {
    // Safely parse the userId from localStorage
    const storedUser = localStorage.getItem("user");
    const userId = storedUser ? JSON.parse(storedUser).userId : null;

    if (userId) {
      // Append userId as a query parameter
      const url = `/Notification/getNotificationbyUserId?userId=${userId}`;
      
      // Fetch notifications
      apiClient(url)
        .then((res) => setNotificationList(res || []))
        .catch((err) => console.error("Error fetching notifications:", err));
    } else {
      console.error("User ID is not available in localStorage");
    }
  }, []);


  //  **************************************** END API CALL **************************************************


  return (
    <>
      {" "}
      <Dropdown>
        <Dropdown.Toggle as="div" id="dropdown-basic" className="bell-dropdown">
          <Badge badgeContent={1} color="primary">
            <a href="#" onClick={(e) => e.preventDefault()}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </Badge>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            maxHeight: "200px", // Limit height
            overflowY: "auto", // Enable vertical scrolling
            width: "300px", // Optional: Adjust dropdown width
            textWrap:"wrap"
            
          }}
        >
          {notificationList
            .map((item:any, index: number) => (
              <Dropdown.Item eventKey={index} onClick={()=>handleShow(item)}  className="text-wrap">
                {item.notificationFromUsername} {NotificationDocumentType[item.docType]} {item.docType==2?"send you a message" :item.documentName ?? ""}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      <PopUp
  handleClose={handleClose}
  show={show}
  title="Notification Details"
  body={singleItem ? (
    <>
      {singleItem.documentName && singleItem.documentPath ? (
        <Tooltip
          title={`Download ${
            singleItem.documentName.includes("_")
              ? singleItem.documentName.split("_").slice(1).join("_")
              : singleItem.documentName
          }`}
          arrow
        >
          <Link
            href={singleItem.documentPath}
            sx={{ textDecoration: "none", display: "flex", alignItems: "center" }}
            target="_blank"
          >
            <div
              role="presentation"
              tabIndex={0}
              style={{
                flex: "1 1 0%",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                borderWidth: "1px",
                borderRadius: "8px",
                borderColor: "rgb(65, 114, 159)",
                borderStyle: "dashed",
                backgroundColor: "rgb(250, 250, 250)",
                color: "rgb(65, 114, 159)",
                outline: "none",
                transition: "border 0.24s ease-in-out",
              }}
            >
              <span style={{ marginRight: "8px" }}>
                <p>{singleItem.documentName}</p>
              </span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.25 13.75V17.4167C19.25 17.9029 19.0568 18.3692 18.713 18.713C18.3692 19.0568 17.9029 19.25 17.4167 19.25H4.58333C4.0971 19.25 3.63079 19.0568 3.28697 18.713C2.94315 18.3692 2.75 17.9029 2.75 17.4167V13.75M6.41667 9.16667L11 13.75M11 13.75L15.5833 9.16667M11 13.75V2.75"
                  stroke="#02A4E3"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </div>
          </Link>
        </Tooltip>
      ) : (
        <p>No document information available</p>
      )}
      <p>
        <b>{singleItem.notificationFromUsername || "Unknown sender"}{" "} </b>
        {NotificationDocumentType[singleItem.docType] || "Unknown type"} on{" "}
        {singleItem.dateCreated
          ? new Date(singleItem.dateCreated).toLocaleString()
          : "Unknown date"}
      </p>
    </>
  ) : (
    <p>No data available</p>
  )}
  
/>


      
    </>
  );
};

export default Notification;
