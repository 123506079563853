import React, { useState, useRef } from "react";

const RevertDocPopup = ({ show, onClose, onSubmit, documentDetails }: any) => {
  const [note, setNote] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  if (!show) return null;

  const isSubmitDisabled = note.trim() === ""; // Validation: Disable submit if note is empty

  return (
    <>
      {/* Overlay to block background interaction */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)", // Semi-transparent overlay
          zIndex: 999, // Ensure it's behind the popup
        }}
        onClick={onClose} // Optional: Close the popup if the overlay is clicked
      ></div>

      {/* Popup */}
      <div
        style={{
          position: "fixed",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          borderRadius: "8px",
          zIndex: 1000, // Higher than overlay
          width: "47%",
          height: "35%",
          display: "flex", // Flexbox for alignment
          flexDirection: "column", // Arrange items vertically
          justifyContent: "space-between", // Add space between content and buttons
        }}
      >
        <h3
          style={{
            fontFamily: "Proxima Nova, Arial, sans-serif",
            fontWeight: "bold",
            marginBottom: "15px",
          }}
        >
          Revert Note
        </h3>
        <textarea
          ref={textareaRef}
          rows={4}
          style={{
            width: "100%",
            marginBottom: "10px",
            padding: "10px",
            fontSize: "14px",
            resize: "none",
            textAlign: "left",
            verticalAlign: "top",
            boxSizing: "border-box",
          }}
          placeholder="Enter your note here..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end", // Align buttons to the right
            marginTop: "auto", // Push buttons to the bottom
          }}
        >
          <button
            style={{
              marginRight: "10px",
              padding: "5px 10px",
              backgroundColor: isSubmitDisabled ? "#d3d3d3" : "#0288D1", // Disabled color
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: isSubmitDisabled ? "not-allowed" : "pointer", // Disabled cursor
            }}
            onClick={() => {
              if (!isSubmitDisabled) { // Prevent action if disabled
                onSubmit(documentDetails, note);
                setNote(""); // Clear note after submission
                onClose();
              }
            }}
            disabled={isSubmitDisabled} // Disable button when note is empty
          >
            Submit
          </button>
          <button
            style={{
              padding: "5px 10px",
              backgroundColor: "grey",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default RevertDocPopup;
