import { LinearProgress, FormHelperText, Checkbox, FormControl, Grid, Button, Box, Link } from "@mui/material";
import { Formik, Form, Field } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { RightNav } from "../Nav/RightNav";
// import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FMTextField } from "../Elements/input";
import { EmailTampateMOdel_VS, EmailTemplateModel } from "../../models/EmailTemplateModel";
import Popper from '@mui/material/Popper';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LoggedInUserInfo } from "../Elements/loggedInUserInfo";
import { KeyInformationPopUp } from "./KeyInformationPopUp";
import { margin } from "@mui/system";
import { apiClient } from "../../helper/api";

import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce'

export interface EmailKeysModel {
    keyName: string
    key: string
}

export const AddEditEmailTemplate = () => {

    const [loading, setloading] = useState<boolean>(true);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();
    const [Open, setOpen] = React.useState(false);

    const handleClickOpen = () => {

        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);
    };

    const initValues: EmailTemplateModel = {
        emailTemplateId: 0,
        templateName: "",
        subject: "",
        content: "",
        createdBy: 0,
        modifiedBy: 0,
        isDeleted: false,
        cc: "",
        bcc: ""
    }
    const goBack = () => {
        navigate(-1);
    }
    const [EmailTemplateData, setEmailTemplateData] = useState<EmailTemplateModel>(initValues)
    // ---
    // const [editorState, seteditorState] = useState<EditorState>(EditorState.createEmpty());

    var initEmailKeys: EmailKeysModel = {
        keyName: "",
        key: ""
    }

    const [EmailKeys, setEmailEmailKeys] = useState<EmailKeysModel[]>([]);
    const [contentVal, setContentVal] = useState<string>('');
    const editorRef = useRef<TinyMCEEditor | null>(null);

    const fetchData = () => {
        setloading(true);
        apiClient(`/EmailTemplate/getById?emailTempId=${params.emailTemplateId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((responseJson) => {
                if (responseJson?.data == null) return
                // Do something with the response

                setEmailTemplateData(responseJson.data);

                // ---
                setContentVal(responseJson.data.content == null ? "" : responseJson.data.content)
                // const contentBlock = convertFromHTML(responseJson.data.content == null ? "" : responseJson.data.content);
                // const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                // const _editorState = EditorState.createWithContent(contentState);
                // seteditorState(_editorState);
                setloading(false);

            }).finally(() => {
                setloading(false);
            });


    }

    const fetchKeysData = () => {
        setloading(true);
        apiClient(`/EmailTemplateKeys/getAll`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (responseJson?.data == null)  return
                // Do something with the response

                setEmailEmailKeys(responseJson.data);

            }).finally(() => {
                setloading(false);
            });


    }

    useEffect(() => {
        fetchKeysData();
        fetchData();

    }, [])




    return (
        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

        // <LoggedInUserInfo ButtonText="" Link="" />


        <div className="col-lg-12 col col-margin">

            <div className="row col-lg-12" style={{ marginBottom: "10px" }}>
                {/* <div className="col-lg-4 d-flex">
                                                    <div style={{ marginRight: "2px" }}>
                                                        <Link href="/">
                                                            <Button type="button" title="Home"
                                                                className="btn action-btn" ><HomeIcon />
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Button

                                                            className=" btn action-btn"
                                                            onClick={goBack}
                                                            title="Back"
                                                        >
                                                            <ArrowBackIosIcon />
                                                        </Button>
                                                    </div>
                                                </div> */}
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="card-title" >Email Template</span>
                    </div>
                    <div  >
                        <button type="submit" onClick={handleClickOpen} className="active-btn-blue me-2 btn btn-primary" title="All the Keys available"> Key Details</button>

                        {/* <button
                                                        style={{ marginRight: "", height: "50px" }}
                                                        className="btn action-btn m-1"
                                                        onClick={handleClickOpen}
                                                        title="All the Keys available"
                                                    >
                                                        Key Details
                                                    </button> */}
                    </div>
                </div>



                <div className="table-responsive" style={{ marginTop: "-20px" }}>
                    <React.Fragment>
                        <Formik
                            enableReinitialize={true}
                            initialValues={EmailTemplateData}
                            validateOnChange={true}
                            validationSchema={EmailTampateMOdel_VS}
                            onSubmit={(data, { setSubmitting }) => {
                                setloading(true);

                                var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


                                if (data.bcc == null || data.bcc === undefined) {
                                    data.bcc = "";
                                }
                                if (data.cc == null || data.cc === undefined) {
                                    data.cc = "";
                                }

                                if (data.bcc != "") {
                                    var isValidBCC = true;
                                    let bccemails = data.bcc.split(",");

                                    bccemails.forEach((email) => (
                                        mailformat.test(email) ? false : isValidBCC = false
                                    ));

                                    if (!isValidBCC) {
                                        setloading(false);
                                        setSubmitting(false);
                                        alert("BCC Email Format is not valid");
                                        return;
                                    }

                                }
                                if (data.cc != "") {
                                    var isValidCC = true;
                                    let ccemails = data.cc.split(",");

                                    ccemails.forEach((email) => (
                                        mailformat.test(email) ? false : isValidCC = false
                                    ));

                                    if (!isValidCC) {
                                        alert("CC Email Format is not valid");
                                        setloading(false);
                                        setSubmitting(false);
                                        return;
                                    }
                                }


                                data.emailTemplateId = parseInt(params.emailTemplateId ?? "0");
                                //  console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))

                                // ---
                                data.content = editorRef.current?.getContent() ?? ''
                                // data.content = draftToHtml(convertToRaw(editorState.getCurrentContent())).toString();

                                // console.log("onsubmit", data);

                                apiClient(`/EmailTemplate/Save`,
                                    {
                                        method: 'POST',
                                        headers: new Headers({
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${token}`,
                                        }),
                                        body: JSON.stringify(data)  // <-- Post parameters
                                    })
                                    .then((res) => {
                                        if (!res) return

                                        // Do something with the response
                                        console.log(res);
                                        if (res.resp == 0) {
                                            navigate("/EmailTemplates");
                                        }

                                    }).finally(() => {
                                        setSubmitting(false);
                                        setloading(false);

                                    });

                            }} >
                            {({ submitForm, values, setFieldValue, isSubmitting }) =>
                            (
                                <Form>




                                    <div className=" ">
                                        <div className="" style={{ margin: "auto" }}>
                                            <Grid container item xs={12} spacing={2} marginTop="30px" marginBottom="30px"  >
                                                <Grid container spacing={2} item xs={12}  >
                                                    <Grid item xs={6}>
                                                        <FormControl sx={{ width: '100%' }} >
                                                            <FormHelperText className="formtexthelper-heading-1" sx={{ fontSize: '2.2rem' }}>Template Name</FormHelperText>

                                                            <FMTextField name="templateName" required autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl sx={{ width: '100%' }} >
                                                            <FormHelperText className="formtexthelper-heading-1" >Email Subject</FormHelperText>
                                                            <FMTextField name="subject" required autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                {/* <Grid item xs={12} display='flex' flexDirection='row'>

                                                                                </Grid> */}
                                                <Grid container spacing={2} item xs={12}  >
                                                    <Grid item xs={6}>
                                                        <FormControl sx={{ width: '100%' }} >
                                                            <FormHelperText className="formtexthelper-heading-1" >CC (you can add multiple emails "," seprated)</FormHelperText>
                                                            <FMTextField name="cc" required autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormHelperText className="formtexthelper-heading-1" >BCC (you can add multiple emails "," seprated)</FormHelperText>
                                                        <FormControl sx={{ width: '100%' }} >
                                                            <FMTextField name="bcc" required autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} spacing={2} >
                                                <Grid className="rounded" container xs={12} display='flex' flexDirection='row'>
                                                    <Grid item xs={12} sx={{ width: '100%' }} >
                                                        <FormHelperText className="formtexthelper-heading-1" >Email Content</FormHelperText>
                                                        {/* <Editor
                                                            wrapperClassName="card m-0 rounded card-noborder"
                                                            editorClassName="editor-class"
                                                            editorState={
                                                                editorState
                                                            }
                                                            onEditorStateChange={newState => {
                                                                seteditorState(newState);
                                                                // console.log("newstate", draftToHtml(convertToRaw(newState.getCurrentContent())));

                                                            }}
                                                        /> */}
                                                        <Editor
                                                            // apiKey="irkngtc5w3ja8rgfdb44w45o5seyw09p6bp602864n97d61q"
                                                            apiKey="qicc1jxe7rehdb1dttly5df1rzzivq8eim1yw5opxh1h7agy" // test user api
                                                            initialValue={contentVal}
                                                            onInit={(evt, editor) => (editorRef.current = editor)}
                                                            init={{
                                                                plugins: [
                                                                  // Core editing features
                                                                  'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount', 'preview', 'fullscreen', 'quickbars', 'code',
                                                                ],
                                                                toolbar: 'undo redo | blocks fontfamily fontsize forecolor backcolor | bold italic underline strikethrough | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | link image media table | code fullscreen preview | removeformat',
                                                                branding: false,
                                                                quickbars_selection_toolbar: 'blocks fontfamily fontsize forecolor backcolor removeformat',
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="mt-4 pb-2" >
                                        {/* <Button variant="outlined" type='submit' className='btn-success' disabled={isSubmitting} >Send</Button> */}
                                        <button type="submit" onClick={submitForm} className="active-btn-blue me-2 btn btn-primary"  disabled={isSubmitting || (!user?.userRoles.split(",").includes("Super Admin"))}>Save</button>
                                        <a href="/EmailTemplates" className=" btn-2 btn  btn-primary btnClose " type="button">Cancel</a>
                                        {/* <button type="submit" style={{ height: "50px" }} onClick={submitForm} className="btn btn-primary submit-btn mt-gutter" disabled={isSubmitting}>Save</button> */}
                                    </div>

                                </Form>


                            )}
                        </Formik>
                    </React.Fragment>
                </div>
                < KeyInformationPopUp handleClose={handleClose} Open={Open} EmailKeysCL={EmailKeys} initValues={initEmailKeys} />
            </div>
        </div>

        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>


    )




}
