import { useEffect, useState } from "react"
import { Box, IconButton, Typography, LinearProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import DescriptionIcon from '@mui/icons-material/Description';
import { apiClient } from "../helper/api"
import useAuth, { authData } from "../context/AuthProvider";
import moment from "moment";
import formatPrice from "../helper/currencyFormatter";
import { AddDocument } from "./AddDocuments";
import { Link } from "react-router-dom";
import useAppDialog from "../hooks/useAppDialog";
import { DialogResponse, DialogResult } from "../components/Dialog/dialogTypes";
// import clipboard from "../assets/clipboard.svg";

interface Transaction {
  rowNum: number;
  invoiceNumber: string;
  insuredName: string;
  policyNumber: string;
  homeState: string;
  transactionTypeName: string;
  invoiceDate: string;
  effectiveDate: string;
  policyExpDate: string;
  grossPremium: string;
  customerpolicyid: number;
  state: string;
  hasMessage: boolean,
}

export const CustomerTransactions: React.FC<{ currentUser: authData | undefined }> = ({ currentUser }) => {
  // Sample data - replace with your actual data source
  const [loading, setloading] = useState(false)
  const { user } = useAuth()
  const [itemList, setItemList] = useState<Transaction[]>([])
  const [ pageSize, setPageSize] = useState(50)
  const [ pageNumber, setPageNumber] = useState(1)
  const [ totalCount, setTotalCount] = useState(0)
  const [sortModel, setSortModel] = useState([{ field: "", sort: "" }]);
  const [filterModel, setFilterModel] = useState({ items: [{ columnField: "", value: "", operatorValue: "" }] });
  const [ID, setID] = useState();
  const [openDoc, setOpenDoc] = useState(false);
  const [selectedHomeState, setSelectedHomeState] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [insuredName, setInsuredName] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');

  const token = user?.token

  var headerHeight = document.getElementsByClassName('customer-header').length > 0 ? document.getElementsByClassName('customer-header')[0].clientHeight : 0;
  headerHeight = document.getElementsByClassName('img-bg-sec').length ? headerHeight + document.getElementsByClassName('img-bg-sec')[0].clientHeight : headerHeight;
  headerHeight = document.getElementsByClassName('MuiDataGrid-footerContainer').length > 0 ? headerHeight + document.getElementsByClassName('MuiDataGrid-footerContainer')[0].clientHeight : headerHeight;

  useEffect(() => {
    fetchData();
    fetchStateData();
  }, [pageNumber, pageSize, filterModel, sortModel])

  const initialStatedata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialStatedata]);
  const { openDialog_Message } = useAppDialog()
  
  const fetchStateData = () => {
    apiClient("/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((responseJson) => {
        if (!responseJson) return
        // Do something with the response

        setStates(responseJson.data);
      })
  };

  const fetchData = () => {
    const params = new URLSearchParams();
    console.log("policyNumber", policyNumber);
    console.log("insuredName",insuredName);
    console.log("invoiceNumber",invoiceNumber);
    console.log("selectedHomeState",selectedHomeState);

    params.append('pageSize', pageSize.toString() || '');
    params.append('pageNumber', pageNumber.toString() || '');
    params.append('fieldName', filterModel?.['items']?.[0]?.['columnField'] || '');
    params.append('value', filterModel?.['items']?.[0]?.['value'] || '');
    params.append('field', sortModel?.[0]?.['field'] || '');
    params.append('sort', sortModel?.[0]?.['sort'] || '');
    params.append('operators', filterModel?.['items']?.[0]?.['operatorValue'] || '');
    params.append('policyNumber', policyNumber);
    params.append('insuredName', insuredName);
    params.append('invoiceNumber', invoiceNumber);
    params.append('selectedHomeState', selectedHomeState);

    apiClient(`/customer/getTransactions?${params.toString()}`, {
      method: "GET",
      headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      }),
    })
    .then(responseJson => {
      if (!responseJson) return

      if (responseJson.data && responseJson.data.getCustomerTransactions_Respdata.length > 0) {
          setItemList(responseJson.data.getCustomerTransactions_Respdata);
          setTotalCount(responseJson.data.totalCount)
          setloading(false);
          console.log("alldata", responseJson.data);
      } else {
          setItemList([]);
          setTotalCount(0)
          setloading(false);
      }
    })
    .catch(error => {
      setItemList([]);
      setTotalCount(0)
      setloading(false);
    });
  };

  const handleClose = () => {
    setOpenDoc(false)
    fetchData()
  };
  const handleSendMessage = async (custPolId: number, status?: string) => {
    try {
      setloading(true);

      openDialog_Message({
        custPolId: String(custPolId)
      }).then((res: DialogResponse) => {
        if (res.dialogResult === DialogResult.OK) {
          // Do something with the message
          
        }
        return res
      })
      setloading(false);

    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const UploadDocument = (id: any) => {
    setID(id);
    setOpenDoc(true);
  }

  const handleHomeStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedHomeState(selectedValue);
  };

  const handlePolicyNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setPolicyNumber(inputValue); // Set the policy number to the state
  };

  const handleInsuredNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setInsuredName(inputValue); // Set the insured name to the state
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setInvoiceNumber(inputValue); // Set the invoice number to the state
  };

  function RunSearch(){
    fetchData();
  }
  function ClearFilter(){
    setSelectedHomeState('');
    setPolicyNumber('');
    setInsuredName('');
    setInvoiceNumber('');
  }

  const columns: GridColDef[] = [
    {
      field: 'customerPolicyId', 
      hide: true,
    },
    { 
      field: 'invoiceNumber', 
      headerName: 'Invoice Number',
      flex: 1,
      minWidth: 185,
    },
    { 
      field: 'insuredName', 
      headerName: 'Insured Name',
      flex: 1,
      minWidth: 170,
    },
    { 
      field: 'policyNumber', 
      headerName: 'Policy Number',
      flex: 1,
      minWidth: 175,
    },
    { 
      field: 'homeState', 
      headerName: 'Home State',
      flex: 1,
      minWidth: 155,
    },
    { 
      field: 'transactionTypeName', 
      headerName: 'Transaction Type',
      flex: 1,
      minWidth: 190,
    },
    { 
      field: 'invoiceDate', 
      headerName: 'Inv Date',
      flex: 1,
      minWidth: 130,
      renderCell: (params) =>
      (
        <div>
          {(params.row.invoiceDate != null && params.row.invoiceDate != '') ? moment(params.row.invoiceDate).format("MM/DD/YYYY") : ""}
        </div>
      )
    },
    { 
      field: 'effectiveDate', 
      headerName: 'Policy Eff Date',
      flex: 1,
      minWidth: 175,
      renderCell: (params) =>
      (
        <div>
          {(params.row.effectiveDate != null && params.row.effectiveDate != '') ? moment(params.row.effectiveDate).format("MM/DD/YYYY") : ""}
        </div>
      )
    },
    { 
      field: 'policyExpDate', 
      headerName: 'Policy Exp Date',
      flex: 1,
      minWidth: 180,
      renderCell: (params) =>
      (
        <div>
          {(params.row.policyExpDate != null && params.row.policyExpDate != '') ? moment(params.row.policyExpDate).format("MM/DD/YYYY") : ""}
        </div>
      )
    },
    {
      field: 'grossPremium',
      headerName: 'Gross Premium',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        let grossPremium = params.value || '';
        let cleanedValue = grossPremium.replace(/[,\$]/g, "").trim();

        if (cleanedValue.startsWith("(") && cleanedValue.endsWith(")")) {
          cleanedValue = "-" + cleanedValue.slice(1, -1);
        }

        const numericValue = parseFloat(cleanedValue);

        return isNaN(numericValue) ? grossPremium : `${formatPrice(numericValue.toString())}`;
      }
    },
    {
      field: 'documentUpload',
      headerName: 'Document Upload',
      flex: 1,
      minWidth: 150,
      sortable: false, 
      filterable: false, 
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => { UploadDocument(params.row.customerpolicyid) }}
            sx={{ 
                color: '#FFF',
                backgroundColor: '#2099d2',
                '&:hover': {
                backgroundColor: '#162f3c',
                color: '#FFF'
                }
            }}
          >
            <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25M12.75 6L9 2.25M9 2.25L5.25 6M9 2.25V11.25" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </IconButton>
          
          <Link to={`https://netorg134974.sharepoint.com/StateInfo/State%20Information/${params.row.state}.aspx`} target="_blank" style={{
            textDecoration: "none",
            borderRadius: '100%',
            marginLeft: '8px',
          }}>
            <Box sx={{
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              width: '40px',
              height: '40px',
              borderRadius: '100%',
              color: '#FFF',
              backgroundColor: '#2099d2',
              '&:hover': {
                backgroundColor: '#162f3c',
                color: '#FFF'
              }
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
              <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z"/>
              <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"/>
              <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
            </svg>
            </Box>
          </Link>
          
          {params.row.hasMessage ?
            <IconButton
                title="Send Message"
                onClick={(evt) => {
                  evt.preventDefault()
                  evt.stopPropagation()
                  handleSendMessage(params.row.customerpolicyid, params.row.status)
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-envelope me-2" viewBox="0 0 18 18">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
            </IconButton>
            : null
          }
          {/* <Link to={`https://netorg134974.sharepoint.com/StateInfo/State%20Information/${params.row.state}.aspx`} target="_blank" style={{ textDecoration: "none" }}>
            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180" width="34" height="34">
              <path fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="5" d="m65.5 18.1h10.2c1 0 2-1 2-2 0-6.7 5.1-12.3 11.8-12.3 6.1 0 11.2 5.6 11.2 12.3 0 1 1.1 2 2.1 2h10.7v13.8h-48z"/>
              <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="5" d="m122.3 23.2h12c7 0 12.7 4.6 12.7 9.7v128.8c0 5.1-5.7 9.7-12.7 9.7h-88.6c-7 0-12.7-4.6-12.7-9.7v-128.8c0-5.1 5.7-9.7 12.7-9.7h8.8"/>
              <g>
                <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="4" d="m83.1 48h19c0.5 0 1.1 0.4 1.1 1.1v19c0 0.5-0.6 1.1-1.1 1.1h-19c-0.7 0-1.1-0.6-1.1-1.1v-19c0-0.7 0.4-1.1 1.1-1.1z"/>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="3" d="m87.8 55.7l3.8 7.7 19-13.6"/>
              </g>
              <g>
                <path fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3" d="m80 90h37"/>
                <path fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3" d="m79 100h52"/>
                <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="4" d="m49.1 82h19c0.5 0 1.1 0.4 1.1 1.1v19c0 0.5-0.6 1.1-1.1 1.1h-19c-0.7 0-1.1-0.6-1.1-1.1v-19c0-0.7 0.4-1.1 1.1-1.1z"/>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="3" d="m53.8 89.7l3.8 7.7 19-13.6"/>
              </g>
              <g>
                <path fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3" d="m80 130h37"/>
                <path fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3" d="m79 140h52"/>
                <path fill-rule="evenodd" fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="4" d="m49.1 122h19c0.5 0 1.1 0.4 1.1 1.1v19c0 0.5-0.6 1.1-1.1 1.1h-19c-0.7 0-1.1-0.6-1.1-1.1v-19c0-0.7 0.4-1.1 1.1-1.1z"/>
                <path fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="3" d="m53.8 129.7l3.8 7.7 19-13.6"/>
              </g>
            </svg>
          </Link> */}
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', paddingBottom: '10px' }} className="customer-header">
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start',
          marginBottom: '20px'
        }}>
          {/* Left side with Welcome message */}
          <Box>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontSize: '28px',
                fontWeight: 'normal',
                marginBottom: '8px'
              }}
            >
              Welcome <strong>{currentUser?.userFirstName} {currentUser?.userlastName}</strong>!
            </Typography>
              
            <Typography 
              variant="body1" 
              sx={{ 
                fontSize: '16px',
                color: '#555'
              }}
            >
              Please upload your filing documents below by selecting the <strong>Document Upload</strong> button to the right of each transaction.
            </Typography>
          </Box>

          {/* Right side with Reference Sheet */}
          {/* <Box 
            className="reference-sheet-button"
          >
            <Link to="https://netorg134974.sharepoint.com/StateInfo/State%20Information/home.aspx" target="_blank">
              <Box sx={{
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                width: '48px',
                height: '60px',
                borderRadius: '6px',
                color: '#FFF',
                backgroundColor: '#2099d2',
                marginLeft: '8px',
                '&:hover': {
                  backgroundColor: '#162f3c',
                  color: '#FFF'
                }
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z"/>
                  <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"/>
                  <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>
              </Box>
              <Box sx={{
                  paddingLeft: '9px'
                }}>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      fontWeight: 'bold',
                      fontSize: '14px',
                      alignItems: 'center',
                      textAlign: 'left',
                      color: '#333'
                    }}
                  >
                    REFERENCE SHEET:
                  </Typography>
                  <Typography 
                    variant="body2"
                    sx={{
                        fontSize: '14px',
                        textAlign: 'left',
                        color: '#555'
                    }}
                  >
                    Required Documents for Filing By State
                  </Typography>
                </Box>
            </Link>
          </Box> */}
        </Box>
      </Box>

      {/* Filter Section */}
      <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mb: 2,
            ml: '186px', // Adds margin-left
          }}
        >
          {/* Home State Dropdown */}
          <select
            style={{
              width: '300px', // Explicit width for dropdown
              height: '40px', // Explicit height for dropdown
              padding: '8px 12px',
              fontSize: '14px',
              border: '1px solid #ccc',
              borderRadius: '20px',
            }}
            value={selectedHomeState} // Bind the state to the select value
            onChange={handleHomeStateChange} // Handle the change event
          >
            <option value="">Home State</option>
            {States.map((state) => (
              <option key={state.stateId} value={state.stateId}>
                {state.stateName}
              </option>
            ))}
          </select>

          {/* Policy Number Input */}
          <input
            type="text"
            placeholder="Policy Number"
            style={{
              width: '300px', // Explicit width for input
              height: '40px', // Explicit height for input
              padding: '8px 12px',
              fontSize: '14px',
              border: '1px solid #ccc',
              borderRadius: '20px',
            }}
            value={policyNumber} // Bind the state to the input value
            onChange={handlePolicyNumberChange} // Handle the change event
          />

          {/* Insured Name Input */}
          <input
            type="text"
            placeholder="Insured Name"
            style={{
              width: '300px', // Explicit width for input
              height: '40px', // Explicit height for input
              padding: '8px 12px',
              fontSize: '14px',
              border: '1px solid #ccc',
              borderRadius: '20px',
            }}
            value={insuredName} // Bind the state to the input value
            onChange={handleInsuredNameChange} // Handle the change event
          />

          {/* Invoice Number Input */}
          <input
            type="text"
            placeholder="Invoice Number"
            style={{
              width: '300px', // Explicit width for input
              height: '40px', // Explicit height for input
              padding: '8px 12px',
              fontSize: '14px',
              border: '1px solid #ccc',
              borderRadius: '20px',
            }}
            value={invoiceNumber} // Bind the state to the input value
            onChange={handleInvoiceNumberChange} // Handle the change event
          />

          {/* Search Button */}
          <div>
            <button
              type="button"
              style={{
                width: '100px',
                height: '40px',
                backgroundColor: '#2099d2',
                color: '#fff',
                border: 'none',
                borderRadius: '20px',
                cursor: 'pointer',
              }}
              onClick={() => {
                RunSearch();
              }}
              className="active-btn1-blue-tabs"
            >
              Search
            </button>
          </div>

          {/* Clear Button */}
          <div>
            <button
              type="button"
              style={{
                width: '100px',
                height: '40px',
                backgroundColor: '#f5f5f5',
                color: '#333',
                border: '1px solid #ccc',
                borderRadius: '20px',
                cursor: 'pointer',
              }}
              onClick={() => {
                ClearFilter();
              }}
              className="btn-clear-refresh btn"
            >
              Clear
            </button>
          </div>

          {/* Refresh Button */}
          <div>
            <button
              type="button"
              style={{
                width: '100px',
                height: '40px',
                backgroundColor: '#f5f5f5',
                color: '#333',
                border: '1px solid #ccc',
                borderRadius: '20px',
                cursor: 'pointer',
              }}
              onClick={() => {
                window.location.reload();
              }}
              className="btn-clear-refresh btn"
            >
              Refresh
            </button>
          </div>
      </Box>


      {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
      <Box className="shadow rounded" sx={{ height: 'calc(100vh - '+headerHeight+'px)', width: '100%', backgroundColor: '#FFF', padding: '0 !important', overflow: 'hidden', border: '1px solid #8cc5e6' }}>
        <DataGridPro
          getRowId={(r) => r.rowNum}
          classes={{
            columnHeaders: "MuiDataGridPro-columnHeaders custom-header",
            virtualScroller: "MuiDataGridPro-virtualScroller",
          }}
          rows={itemList}
          columns={columns}
          rowCount={totalCount}
          pageSize={pageSize}
          pagination
          paginationMode="server" // Enable server-side pagination
          onPageChange={(newPage: number) => setPageNumber(newPage + 1)} // Trigger page change
          rowsPerPageOptions={[10, 25, 50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          filterMode="server"
          onFilterModelChange={(newFilterModel: any) => setFilterModel(newFilterModel)} // For filtering
          sortingMode="server"
          sortingOrder={['asc', 'desc']}
          onSortModelChange={(newSortModel: any) => setSortModel(newSortModel)} // For sorting
          loading={loading} // Show loading indicator during fetch
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            fontFamily: "'Proxima Nova', sans-serif",
            border: "0px",
            fontSize: "16px",
          }}
          onRowClick={(params, evt) => {
            console.log(params.row)
            evt.preventDefault()
            evt.stopPropagation()
            // handleSendMessage(params.row.customerPolicyId)
          }}
        />
      </Box>
      <AddDocument open={openDoc} handleClose={handleClose} customerPolicyId={ID}></AddDocument>
    </Box>

  );
};