import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Form, Formik, useFormik } from "formik";
import { FMTextField } from "../../components/Elements/input";
import * as yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers';
import useAuth from '../../context/AuthProvider';
import moment from 'moment';
import { CustomerPolicyDetailsModel } from '../../models/CustomerPolicyDetailsModel';
import { NewCompletedFilingsModel } from '../../models/NewCompletedFilingsModel';
import { config } from '../../Constants';
import Swal from 'sweetalert2';
import { PolicyStatuses } from './PolicyStatuses';
import { apiClient } from '../../helper/api';
import { StateReportModel, StateReportModel_Resp } from '../../models/StateReportModel';
import { FormLabel } from 'react-bootstrap';

interface Iprops {
    handleClose: () => void;
    open: boolean;
    currentStatus?: string;
    handleSubmit: (data: {
        status: string,
        noteType: string,
        noteDetail: string,
        noteUser: string,
        firstRequestDate: string,
        secondRequestDate: string,
        thirdRequestDate: string,
        suspendedDate: string,
        noteDate?: string,
        policySubStatus: string,
        dateDeclarationFiled: string,
        dateFinalReportSentToClient: string,
        taxAmount: string,
        largeReport: boolean
    }) => void;
    currentPolicySubStatus?: string
    selectedRows?: StateReportModel[] | null
}
const StateReportFilerNotes = ({ open, handleClose, handleSubmit, selectedRows = [], currentStatus, currentPolicySubStatus }: Iprops) => {
    //currentPolicySubStatus = "Filed on Binder"
    const { user } = useAuth();
    const token = user?.token;
    const validationSchema = yup.object().shape({
        noteOption: yup.string().required('Select an option'),
        noteDetail: yup.string().when('noteOption', {
            is: 'option1',
            then: yup.string().min(5, 'Note must be at least 5 characters long').required('Required'),
            otherwise: yup.string()
        }),
    });
    // const validationSchema = yup.object().shape({
    //     notes: yup.string().required('Note is required').min(5, 'Note must be at least 5 characters long'),
    // }).optional();

    const options = [
        'Select One',
        'Missing Documents',
        'Corrections Required',
        //'Filed on Binder',
        // 'First request',
        // 'Second request',
        // 'Third&Final request',
        // 'Escalation',
        // 'Other',
    ]
    const optionsBinder = [
        'Select One',
        'Filed on Binder',
        'Filed on Declarations Page',
    ]
    const enum optionsForFiledBinder {
        SelectOne = 'Select One',
        FiledOnBinder = 'Filed on Binder',
        FiledOnDeclarationsPage = 'Filed on Declarations Page',
    }
    const detailOptions = [
        'Filed on binder, need dec page',
        'Date Dec Page received',
    ]
    const [noteOption, setNoteOption] = useState(options[0]);
    const [noteOptionBinder, setNoteOptionBinder] = useState(optionsBinder[0]);

    const [noteDetail, setNoteDetail] = useState<string>('')
    const [noteDetailFiled, setNoteDetailFiled] = useState<string>('')
    const [taxAmount, setTaxAmount] = useState<string>('')
    const [largeReport, setLargeReport] = React.useState(false);
    //const [noteDate, setNoteDate] = useState<Date >(new Date());
    const [noteDate, setNoteDate] = useState<string>('');
    const [DecDate, setDecDate] = useState<Date>();
    const [error, setError] = useState('');
    const [homeState, setHomeState] = useState<string>('');
    const [transTypeName, setTransTypeName] = useState<string>('');
    const initialValuesNote = { noteOption: options[0], noteDetail: '' };
    const shouldDisableDate = (date: Date) => {
        const minDate = new Date('1900-01-01')
        const maxDate = new Date()
        return date < minDate || date > maxDate;
    };
    const isNYBusiness = homeState === 'NY' && (transTypeName === 'New Business' || transTypeName === 'Renewal Business') && selectedRows?.length === 1
    const isNYReceivedDecPage = isNYBusiness && currentStatus === 'Filed'

    const handleNoteOptionChange = (event: SelectChangeEvent) => {
        const update = event.target.value
        setNoteOption(update)
        if (update === 'Filed on Binder') {
            setNoteDetail(detailOptions[0])
            setShowNoteDateField(true);
            setNoteDate(new Date().toLocaleDateString());
        }
    }
    const handleNoteOptionChangeFiled = (event: SelectChangeEvent) => {
        const update = event.target.value
        setNoteOptionBinder(update)
        if (update === 'Filed on Binder') {
            setNoteDetailFiled(detailOptions[0]);
        } else {
            setNoteDetailFiled('');
        }
    }
    const handleNoteDetailChangeFiled = (event: any) => {
        const update = event.target.value
        setNoteDetailFiled(update)
        setTaxAmount(update)
    }
    const handleNote = (event: any) => {
        const update = event.target.value
        setNoteDetail(update)
    }
    const handleNoteDetailChange = (event: any) => {
        console.log(event.target.value)
        const update = event.target.value
        setNoteDetail(update)
        setShowNoteDateField(update !== '');
        setNoteDate(update === '' ? new Date().toLocaleDateString() : noteDate);
    }
    const handleNoteDate = (event: any) => {
        setNoteDate(event)
    }
    const handleDecDate = (event: Date | null) => {
        if (event != null) {
            //setDecDate(moment(event)?.format("MM/DD/YYYY"));
            setDecDate(event);
        }
    }
    useEffect(() => {
        if (selectedRows && selectedRows.length === 1) {
            console.log("currentStatus", currentStatus)
            // const customerPolicyId = selectedRows[0].customerpolicyid;
            // setHomeState(selectedRows[0].homeState ?? '');
            // setTransTypeName(selectedRows[0].transactionTypeName ?? '');
            // fetchCustomerPolicyByCustPolicyId(customerPolicyId);
        }
        else {
            setNoteDetail('')
            setFirstRequestDate('');
            setSecondRequestDate('');
            setThirdRequestDate('');
            setNoteDate(new Date().toISOString());
            setSuspendedDate('');
        }
    }, [selectedRows]);

    const handleBoxClose = () => {
        setNoteDetail('')
        setFirstRequestDate('');
        setSecondRequestDate('');
        setThirdRequestDate('');
        setNoteDate(new Date().toISOString());
        setSuspendedDate('');
        setTaxAmount('');
        setNoteDetailFiled('');
        setDateComplete('');
        setLargeReport(false);
        handleClose();
    }

    const getFilteredOptions = () => {
        if (homeState === 'NY' && (transTypeName === 'New Business' || transTypeName === 'Renewal Business')) {
            // if (currentStatus === 'Filed') return [options[2]]
            return options;
        }
        return options.slice(0, 3);
    };
    // const initCustomerPolicyDetails: CustomerPolicyDetailsModel = {}
    // const [CustomerPolicyDetails, setCustomerPolicyDetails] = useState(initCustomerPolicyDetails)
    const fetchCustomerPolicyByCustPolicyId = (customerPolicyId: number) => {
        //setloading(true);
        apiClient(`/CustomerPolicy/getCustomerPolicyDetails?custPolicyId=${customerPolicyId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                //  setloading(false);
                if (res.data != null) {
                    var data = res.data;
                    //  setCustomerPolicyDetails(data);
                    setFirstRequestDate(data.firstRequest ?? '');
                    setSecondRequestDate(data.secondRequest ?? '');
                    setThirdRequestDate(data.thirdRequest ?? '');
                    setSuspendedDate(data.suspended ?? '');
                    setNoteDate(data.notesDate ? data.notesDate.toLocaleDateString() : new Date().toLocaleDateString());
                    const filerNotes = data.filerNotes
                    if (filerNotes.includes(detailOptions[0]) || filerNotes.includes(detailOptions[1])) {
                        setNoteOption(options[2]);

                        if (currentStatus === 'Filed' && !filerNotes.includes(detailOptions[1])) {
                            setNoteDetail(detailOptions[1])
                            return
                        }
                    }
                    setNoteDetail(filerNotes)
                }
            })
    }
    interface NotesDetails {
        customerPolicyIds?: number[],
        option?: string,
        policySubStatus?: string
    }

    const updateFilerNotes = (customerPolicyId: number[], note: string, policySubStatus: string) => {
        const notesDetailsValue: NotesDetails = {
            customerPolicyIds: customerPolicyId,
            option: note,
            policySubStatus: policySubStatus
        }
        apiClient(`/PolicyDashBoard/updateFilerNotes`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                body: JSON.stringify(notesDetailsValue)
            })
            .then((res) => {
                if (res.data != null) {
                    Swal.fire({
                        title: res.data,
                        text: res.message,
                        icon: res.data,
                        confirmButtonText: "OK",
                    }).then(() => {
                        window.location.reload()
                    });
                }
            })
    }
    const [firstRequestDate, setFirstRequestDate] = useState('')
    const [secondRequestDate, setSecondRequestDate] = useState('')
    const [thirdRequestDate, setThirdRequestDate] = useState('')
    const [suspendedDate, setSuspendedDate] = useState('')
    const [showNoteDateField, setShowNoteDateField] = useState(false);
    const [dateComplete, setDateComplete] = useState('');
    const handleDateComplete = (val: string | undefined) => {
        if (val != null) { setDateComplete(moment(val)?.format("MM/DD/YYYY")) }
    }
    const handleFirstRequestChange = (val: string | undefined) => {
        if (val != null) { setFirstRequestDate(moment(val)?.format("MM/DD/YYYY")) }
    }
    const handleSecondRequestChange = (val: string | undefined) => {
        if (val != null) { setSecondRequestDate(moment(val)?.format("MM/DD/YYYY")) }
    }
    const handleThirdRequestChange = (val: string | undefined) => {
        if (val != null) { setThirdRequestDate(moment(val)?.format("MM/DD/YYYY")) }

    }
    const handleSuspendedRequestChange = (val: string | undefined) => {
        if (val != null) { setSuspendedDate(moment(val)?.format("MM/DD/YYYY")) }

    }

    return (
        <Dialog open={open} onClose={handleBoxClose} maxWidth={'lg'}>
            {currentStatus === "Filed" ?
                <DialogTitle className='card-title'>Filed Notes</DialogTitle>
                :
                <DialogTitle className='card-title'>Filer Notes</DialogTitle>}
            <React.Fragment>
                <Formik
                    initialValues={initialValuesNote}
                    // enableReinitialize={true}
                    // validateOnChange={true}
                    // validationSchema={validationSchema}
                    onSubmit={(data) => {
                        var dateDeclarationFiled = ''
                        let notes = ''
                        let policySubStatus = ''
                        // if (currentStatus === PolicyStatuses.Filed && noteOptionBinder === optionsForFiledBinder.FiledOnBinder) {
                        //     // var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];
                        //     var customerPolicyIds: number[] =  [];
                        //     updateFilerNotes(customerPolicyIds, noteDetailFiled, noteOptionBinder)
                        //     handleClose()
                        //     return;
                        // }
                        // if (currentStatus === PolicyStatuses.Filed && noteOptionBinder ===optionsForFiledBinder.FiledOnDeclarationsPage && currentPolicySubStatus !== optionsForFiledBinder.FiledOnBinder) {

                        //     policySubStatus = noteOptionBinder;
                        //     notes = noteDetailFiled;
                        // }
                        // if (currentStatus === PolicyStatuses.WaitingOnClient) {
                        //     policySubStatus = noteOption
                        //     notes = noteDetail !== null && noteDetail !== "" ? noteDetail : noteOption
                        // }

                        // if (!DecDate && currentStatus === PolicyStatuses.Filed && noteOptionBinder !== optionsForFiledBinder.FiledOnBinder && currentPolicySubStatus ===optionsForFiledBinder.FiledOnBinder) {
                        //     setError('This field is required');
                        //     return;
                        // } else if (currentStatus === PolicyStatuses.Filed) {
                        //     dateDeclarationFiled = DecDate?.toString() ?? '';
                        //     var newDateFormatted = moment(dateDeclarationFiled)?.format("MM/DD/YYYY");
                        //     newDateFormatted = newDateFormatted === "Invalid date" ? '' : newDateFormatted
                        //     notes = 'Dec Page received Date: ' + newDateFormatted;
                        // }
                        // if (!DecDate && currentStatus === PolicyStatuses.Filed && noteOptionBinder === optionsForFiledBinder.SelectOne) {
                        //     setError('This field is required');
                        //     return;
                        // }
                        handleSubmit({
                            status: currentStatus ?? '',
                            noteDetail: noteDetail,
                            noteType: noteOption,
                            noteUser: user?.userFirstName ?? '',
                            firstRequestDate: firstRequestDate !== "Invalid date" ? firstRequestDate : '',
                            secondRequestDate,
                            thirdRequestDate,
                            suspendedDate,
                            noteDate: noteDate !== "Invalid date" ? noteDate : '',
                            policySubStatus: policySubStatus ?? '',
                            dateDeclarationFiled: dateDeclarationFiled ?? '',
                            dateFinalReportSentToClient: dateComplete ?? '',
                            taxAmount: taxAmount ?? '',
                            largeReport: largeReport
                        });
                        handleBoxClose(); // Pass the form data to the handler
                    }
                    }
                >
                {({ values }) => {
                    const canSubmit =
                        currentStatus !== 'Filed' ||
                        (!!dateComplete && !!noteDetailFiled); // Both required for 'Filed'

                    return (
                        <Form className='main-form' >
                            <DialogContent >

                                {(currentStatus === "Filed" || currentStatus === "No Report Required") ?
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <DatePicker
                                                        shouldDisableDate={shouldDisableDate}
                                                        inputFormat="MM/DD/YYYY"
                                                        label="Date Completed"
                                                        className="select-input-border text-input-box"
                                                        value={dateComplete ?? ''}
                                                        onChange={(v) => handleDateComplete(v?.toString())}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                {...params}
                                                                id="dateComplete"
                                                                name="dateComplete"
                                                                error={!dateComplete}
                                                                helperText={
                                                                    !dateComplete 
                                                                        ? 'Date Completed is required'
                                                                        : ''
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {currentStatus === 'Filed' && (
                                            <>
                                                {/* Tax Amount Field */}
                                                <Grid item xs={12}>
                                                    <FormControl sx={{ width: '100%', minWidth: '400px' }}>
                                                        <TextField
                                                            label={"Tax Amount"}
                                                            placeholder={"Enter '0' for Zero Reports"}
                                                            type="number" // Native numeric input
                                                            fullWidth
                                                            variant="outlined"
                                                            sx={{ marginTop: 2 }}
                                                            value={noteDetailFiled}
                                                            required={noteOptionBinder === optionsForFiledBinder.FiledOnBinder}  // Ensures that the field is required if certain condition is met
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                // Allow numbers with optional decimal part
                                                                if (/^\d*\.?\d*$/.test(value)) {
                                                                    handleNoteDetailChangeFiled(e);  // Handle the note change
                                                                }
                                                            }}
                                                            InputProps={{
                                                                inputProps: { min: 0, step: "0.01" } // Step ensures float precision up to two decimal places
                                                            }}
                                                            error={!noteDetailFiled && currentStatus === 'Filed'}  // Display error if empty and status is 'Filed'
                                                            helperText={!noteDetailFiled && currentStatus === 'Filed' ? 'This field is required' : ''}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                {/* Large Report Field */}
                                                <Grid item xs={12}>
                                                    <FormControl sx={{ width: '100%', minWidth: '400px', marginTop: 2 }}>
                                                        <FormLabel>Large Report</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            value={largeReport}
                                                            onChange={(e) => setLargeReport(e.target.value === 'true')}
                                                        >
                                                            <FormControlLabel value="true" control={<Radio />} label="True" />
                                                            <FormControlLabel value="false" control={<Radio />} label="False" />
                                                        </RadioGroup>
                                                        {/* Display error if not selected and status is 'Filed' */}
                                                        <FormHelperText error={largeReport === null && currentStatus === 'Filed'}>
                                                            {largeReport === null && currentStatus === 'Filed' ? 'This field is required' : ''}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        )}


<Grid item xs={12}>
    <FormControl sx={{ width: '100%', minWidth: '400px' }}>
        <TextField
            label={"Note"}
            rows={3}
            type="text"
            fullWidth
            variant="outlined"
            sx={{ marginTop: 2 }}
            multiline
            value={noteDetail}
            onChange={handleNote}
            required={currentStatus === "No Report Required"} // Make it required for No Report Required
            error={!noteDetail && currentStatus === "No Report Required"} // Mark as error if empty and status is No Report Required
            helperText={
                !noteDetail && currentStatus === "No Report Required" 
                    ? 'Note is required ' 
                    : ''
            } // Display helper text
        />
    </FormControl>
</Grid>
                                    </Grid>

                                    :
                                    <Grid container spacing={2}>

                                        {/* <Grid item xs={12}>
                                                <FormControl sx={{ width: '100%' }} className="main-form-2 " >
                                                    <InputLabel id="State-Select-Label" className="border-round ">Select Note</InputLabel>
                                                    <Select
                                                        // name={'noteOption'}
                                                        value={noteOption}
                                                        disabled={isNYReceivedDecPage}
                                                        onChange={handleNoteOptionChange}
                                                        labelId="State-Select-Label"
                                                        label={"Select State"}
                                                        IconComponent={ExpandMoreIcon}
                                                    >
                                                        {getFilteredOptions().map((val, key) =>
                                                            val == "Select One" ?
                                                                <MenuItem key={key} value={val}><span style={{ color: 'red' }}>{val}</span></MenuItem>
                                                                : <MenuItem key={key} value={val}>{val}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid> */}
                                        <Grid item xs={12}>
                                            <FormControl sx={{ width: '100%', minWidth: '500px' }}>
                                                <FormHelperText className='formtexthelper-heading'>
                                                    Note
                                                </FormHelperText>
                                                <TextField
                                                    label={"Note"}
                                                    rows={3}
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    sx={{ marginTop: 2 }}
                                                    multiline
                                                    value={noteDetail}
                                                    onChange={(e) => handleNoteDetailChange(e)}
                                                    required
                                                    error={!noteDetail.trim()} // Mark as error if noteDetail is empty
                                                    helperText={!noteDetail.trim() ? 'Note is required' : ''} // Show helper text if empty
                                                />
                                            </FormControl>
                                        </Grid>


                                        <Grid item xs={12} sx={{ marginTop: 2 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <DatePicker
                                                        shouldDisableDate={shouldDisableDate}
                                                        inputFormat="MM/DD/YYYY"
                                                        label="First Request"
                                                        className="select-input-border text-input-box"
                                                        value={firstRequestDate ?? ''}
                                                        onChange={(v) => handleFirstRequestChange(v?.toString())}
                                                        renderInput={(params: any) => (
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                {...params}
                                                                id="firstRequestDate"
                                                                name="firstRequestDate"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <DatePicker
                                                        shouldDisableDate={shouldDisableDate}
                                                        inputFormat="MM/DD/YYYY"
                                                        label="Second Request"
                                                        className="select-input-border text-input-box"
                                                        value={secondRequestDate ?? ''}
                                                        onChange={(v) => handleSecondRequestChange(v?.toString())}
                                                        renderInput={(params: any) => (
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                {...params}
                                                                id="secondRequestDate"
                                                                name="secondRequestDate"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <DatePicker
                                                        shouldDisableDate={shouldDisableDate}
                                                        inputFormat="MM/DD/YYYY"
                                                        label="Third & Final Request"
                                                        className="select-input-border text-input-box"
                                                        value={thirdRequestDate ?? ''}
                                                        onChange={(v) => handleThirdRequestChange(v?.toString())}
                                                        renderInput={(params: any) => (
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                {...params}
                                                                id="thirdRequestDate"
                                                                name="thirdRequestDate"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={3}>
                                                        <DatePicker
                                                            shouldDisableDate={shouldDisableDate}
                                                            inputFormat="MM/DD/YYYY"
                                                            label="Suspended"
                                                            className="select-input-border text-input-box"
                                                            value={suspendedDate ?? ''}
                                                            onChange={(v) => handleSuspendedRequestChange(v?.toString())}
                                                            renderInput={(params: any) => (
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    {...params}
                                                                    id="suspendedDate"
                                                                    name="suspendedDate"
                                                                />
                                                            )}
                                                        />
                                                    </Grid> */}
                                            </Grid>
                                            <Grid item xs={.3}></Grid>
                                            {(showNoteDateField) && (
                                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} marginTop={3}>
                                                    <FormHelperText className='formtexthelper-heading' >Note Date</FormHelperText>
                                                    <DatePicker
                                                        inputFormat="MM/DD/YYYY"
                                                        label={null}
                                                        className="select-input-border text-input-box"
                                                        shouldDisableDate={shouldDisableDate}
                                                        value={noteDate}
                                                        onChange={handleNoteDate}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                {...params}
                                                                id="effectiveDateStart"
                                                                name="policyEffDate" />
                                                        )}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                }
                            </DialogContent>
                            {/* <DialogActions>
                                <button className='btn-2 btn d-flex btn-primary search-btn' type='button' onClick={ handleBoxClose}>Cancel</button>
                                {user?.userRoles.split(",").includes("Super Admin") ||
                                    user?.userRoles.split(",").includes("MISC Filer") ||
                                    user?.userRoles.split(",").includes("MISC State Reporter")
                                    ?
                                    (
                                        currentStatus === "Filed" ?
                                        <button
                                        className="btn btn-primary active-btn-blue search-btn"
                                        type="submit"
                                        disabled={!canSubmit} // Disable if required fields are not filled
                                    >
                                        Submit
                                    </button>
                                            :
                                            <button className='btn btn-primary active-btn-blue search-btn' type='submit' >Submit</button>
                                    )
                                    :
                                    <button className='btn btn-primary active-btn-blue search-btn' disabled>Submit</button>
                                }
                            </DialogActions> */}
                        <DialogActions>
                            <button
                                className='btn-2 btn d-flex btn-primary search-btn'
                                type='button'
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            {user?.userRoles.split(",").includes("Super Admin") ||
                            user?.userRoles.split(",").includes("MISC Filer") ||
                            user?.userRoles.split(",").includes("MISC State Reporter") ? (
                                (currentStatus === "Filed" || currentStatus === "No Report Required") && dateComplete ? (
                                    <button
                                        className='btn btn-primary active-btn-blue search-btn'
                                        type='submit'
                                    >
                                        Submit
                                    </button>
                                ) : currentStatus === "Waiting on Client" &&
                                ((firstRequestDate || secondRequestDate || thirdRequestDate) && noteDetail) ? (
                                    <button
                                        className='btn btn-primary active-btn-blue search-btn'
                                        type='submit'
                                    >
                                        Submit
                                    </button>
                                ) : (
                                    <button
                                        className='btn btn-primary active-btn-blue search-btn'
                                        type='submit'
                                        disabled
                                    >
                                        Submit
                                    </button>
                                )
                            ) : (
                                <button
                                    className='btn btn-primary active-btn-blue search-btn'
                                    disabled
                                >
                                    Submit
                                </button>
                            )}
                        </DialogActions>
                        </Form>                    
                        );
                }}
                </Formik>
            </React.Fragment>
        </Dialog>



    );
};

export default StateReportFilerNotes;
