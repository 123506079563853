import { createContext, ReactNode, useCallback, useRef, useState } from "react"
import { DialogResponse } from "../components/Dialog/dialogTypes"

interface DialogContextType {
  dialogParams: DialogParamType | undefined,
  resolve: ((value: DialogResponse) => void) | undefined,
  setDialogParams: React.Dispatch<React.SetStateAction<DialogParamType | undefined>>,

  isMessageDialog: boolean | undefined,
  setIsMessageDialog?: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  openDialog_Message: (val?: DialogParamType) => Promise<DialogResponse>,
  closeDialog_Message: () => void,
}

const DialogContext = createContext({} as DialogContextType)

const initiateState = {
  // show/hide <Messages> dialog
  showMessageDialog: false,
}

interface DialogParamType {
  custPolId?: string
  resolveDialog?: (result: DialogResponse) => void
}

export const DialogContextProvider: React.FC<{ children: ReactNode }> = (props) => {
  const { children } = props

  //////////////////////////
  //  Data for dialogs
  const [dialogParams, setDialogParams] = useState<DialogParamType>()
  const refResolve = useRef<(value: DialogResponse) => void>()

  //////////////////////////
  //  display dialogs
  const [isMessageDialog, setIsMessageDialog] = useState<boolean | undefined>(initiateState.showMessageDialog)

  //////////////////////////
  //  Actions for dialogs
  const openDialog_Message = useCallback((val?: DialogParamType): Promise<DialogResponse> => {
    setDialogParams(val)
    setIsMessageDialog(true)
    return new Promise((resolve) => {
      refResolve.current = resolve
    })
  }, [])

  const closeDialog_Message = () => {
    setIsMessageDialog(false)
  }

  return (
    <DialogContext.Provider
      value={{
        dialogParams,
        resolve: refResolve.current,
        setDialogParams,
        // 
        isMessageDialog,
        setIsMessageDialog,
        openDialog_Message,
        closeDialog_Message,
      }}
    >
      {children}
    </DialogContext.Provider>
  )
}

export default DialogContext
