import moment from "moment";
import formatPrice from "../../helper/currencyFormatter";

export interface RowData {
  state: string;
  insuredName: string;
  policyNumber: string;
  invoiceNumber: string;
  filerNotes: string;
  turnBackDate?: string;
  firstRequest?: string;
  secondRequest?: string;
  thirdRequest?: string;
  taxType: string;
  transactionType: string;
  transactionEffDate: string;
  invoiceDate: string;
  grossPremium: number;
  taxAmt: number;
  suspended?: string;
  [key: string]: any;
}

export interface GrpData {
  [state: string]: {
    count: number;
    insuredGroups: {
      [insuredName: string]: {
        [policyNumber: string]: {
          [invoiceNumber: string]: RowData[];
        };
      };
    };
  };
}

interface Column {
  headerName: string;
  field: string;
  hide: boolean;
}

const formatValue = (value: any, field: string): string => {
  if (!value || value == 'null') return "";
  switch (field) {
    case "transactionEffDate":
        return moment(value).format("MM/DD/YYYY");
    case "invDate":
        return moment(value).format("MM/DD/YYYY");
    case "grossPremium":
        return formatPrice(value)
    case "taxAmt":
        return formatPrice(value)
    case "turnBackDate":
        return moment(value).format("MM/DD/YYYY");
    case "followedUp":
        return moment(value).format("MM/DD/YYYY");
    default:
      return value.toString();
  }
};

export const PrintData = (grpData: GrpData, columnNames: Column[], logoSvg: string, clientName: string | undefined): string => {
  if(Object.keys(grpData).length === 0) {
    grpData = {
        "": {
            "count": 0,
            "insuredGroups": {
                "": {
                    "": {
                        "": []
                    }
                }
            }
        }
    }
  }
  let html = `
    <html>
      <head>
        <title>Outstanding Transactions</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 20px;
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
          }
          @media print {
            * {
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
          }
          .header {
            display: flex;
            margin-bottom: 30px;
          }
          .header img {
            height: 40px;
          }
          .header .title {
            font-size: 24px;
            font-weight: bold;
            margin-top: 10px;
            margin-left: 10px;
          }
          .subtitle {
            font-size: 18px;
            font-weight: bold;
            margin-top: 5px;
            margin-left: 59px
          }
          .state-section {
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .detail-label {
            font-weight: bold;
          }
          .record-count {
            font-size: 16px;
            margin-left: 20px;
            display: inline-block;
          }
          .insured-section {
            margin-top: 15px;
          }
          .insured-name {
            font-weight: bold;
            margin-bottom: 10px;
            margin-left: 0.5%;
          }
          .policy-number {
            padding: 8px;
            margin: 10px 0;
          }
          .policy-number-label {
            font-weight: bold;
            margin-left: 3%;
          }
          .invoice-details {
            margin: 10px 0 10px 20px;
            border-collapse: collapse;
          }
          .invoice-details td {
            border: none;
            padding: 4px;
            font-size: 14px;
          }
          .detail-label {
            font-weight: bold;
          }
          table {
            width: 90%;
            margin-left: 10%;
            border: hidden
            margin-top: 10px;
          }
          th, td {
            padding: 8px;
            text-align: left;
            font-size: 14px;
            border-width: 0;
          }
          th {
            background-color: #bdbbbb;
            font-weight: bold;
          }
          .footer {
            margin-top: 30px;
            text-align: right;
            font-size: 14px;
            color: #666;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <img src="${logoSvg}" alt="Logo">
          <div class="title">Outstanding Transactions</div>
        </div>
        <div class="subtitle">${formatValue(clientName, 'client')}</div>
  `;

  for (const [state, stateData] of Object.entries(grpData)) {
    const { count, insuredGroups } = stateData;
    html += `
      <div class="state-section">
        <table style="width: 100%;margin-left: 0;">
          <tr>
            <td style="width: 150px;"><span class="detail-label" style="font-size: large;">HomeState </span> <span style="font-weight: normal;font-size: large;">${formatValue(state, 'state')}</span></td>
            <td style="text-align: center;"><span class="record-count">Record Count ${count}</span></td>
            <td style="width: 150px;"></td>
          </tr>
        </table>
      </div>
    `;

    for (const [insuredName, policies] of Object.entries(insuredGroups)) {
      html += `
        <div class="insured-section">
          <div class="insured-name">
            <span class="detail-label">InsuredName</span> <span style="font-weight: normal;">${formatValue(insuredName, 'insuredName')}</span>
          </div>
      `;

      for (const [policyNumber, invoices] of Object.entries(policies)) {
        html += `
          <div class="policy-number" style="background-color: #e1dede;">
            <span class="policy-number-label">PolicyNumber</span> <span style="margin-left: 2%;">${formatValue(policyNumber, 'policyNumber')}</span>
          </div>
        `;

        for (const [invoiceNumber, rows] of Object.entries(invoices)) {
          html += `
            <table class="invoice-details" style="margin-bottom: 10px; width: 100%;margin-left: 10%;">
              <tr>
                <td style="width: 120px; text-align: right; padding-right: 10px;"><span class="detail-label">InvoiceNumber</span></td>
                <td style="width: 40%;">${formatValue(invoiceNumber, 'InvoiceNumber')}</td>
              </tr>
              <tr>
                <td style="text-align: right; padding-right: 10px;"><span class="detail-label">Notes</span></td>
                <td colspan="3" style="color: #14ace9;font-weight: 600;">${formatValue(rows[0]?.filerNotes, 'filerNotes')}</td>
              </tr>
              <tr>
                <td style="text-align: right; padding-right: 10px;"><span class="detail-label">Followed Up</span></td>
                <td colspan="3">${formatValue(rows[0]?.thirdRequest || rows[0]?.secondRequest || rows[0]?.firstRequest, 'followedUp')}</td>
                <td style="width: 120px; text-align: right; padding-right: 10px;"><span class="detail-label">TurnBackDate</span></td>
                <td>${formatValue(rows[0]?.suspended, 'turnBackDate')}</td>
              </tr>
            </table>
            <table style="border-collapse: collapse;">
              <thead>
                <tr>
                  ${columnNames
                    .filter(col => !col.hide)
                    .map(col => `<th>${col.headerName}</th>`)
                    .join("")}
                </tr>
              </thead>
              <tbody>
                ${rows
                  .map(
                    row => `
                      <tr>
                        ${columnNames
                          .filter(col => !col.hide)
                          .map(col => `
                            <td>${
                              formatValue(row[col.field], col.field)
                            }</td>
                          `)
                          .join("")}
                      </tr>
                    `
                  )
                  .join("")}
              </tbody>
            </table>
          `;
        }
      }
      html += `</div>`;
    }
  }

  const currentDate = moment().format("M/D/YYYY");
  html += `
        <div class="footer">
          ${currentDate}
        </div>
      </body>
    </html>
  `;
  
  return html;
};