import useAppDialog from "../../hooks/useAppDialog"
import { DialogResult } from "./dialogTypes"
import MessageDialog from "./MessageDialog"

// This component is for managing all modal dialogs within the app.
const AppDialogs = () => {
  const { dialogParams, resolve, isMessageDialog, closeDialog_Message } = useAppDialog()
  return (
    <>
      {isMessageDialog && dialogParams?.custPolId &&
        <MessageDialog
          open={isMessageDialog}
          custPolicyID={dialogParams.custPolId}
          onSubmit={(message) => {
            resolve?.({ dialogResult: DialogResult.OK, data: { message } })
            closeDialog_Message()
          }}
          onClose={() => {
            resolve?.({ dialogResult: DialogResult.Cancel })
            closeDialog_Message()
          }}
        />
      }
    </>
  )
}
export default AppDialogs