
import { Dialog, DialogActions, DialogContent, DialogTitle, debounce, FormControl, Grid, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps, TextField, } from "@mui/material";

import Box from "@mui/material/Box";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { FMFilters, getCompletedFilings__Resp, NewCompletedFilingsModel, getPolicyFilingAndReport } from "../../models/NewCompletedFilingsModel";
import { RemovedFilingQueue } from "./RemovedFilingQueue";
import { RemovedReconciliationQueue } from "./RemovedReconclilationQueue";
import Swal from "sweetalert2";
import moment from "moment";
import { writeXlsxFile } from "../../helper/utils";
import { columnListDetailed, columnListShort, excelItemsAll, excelItemsDetailed, excelItemsShort } from "../../components/Elements/ColumnList";
import { GridFilterItem, GridFilterModel, GridLinkOperator, GridSortModel } from "@mui/x-data-grid-pro";
import { isValid } from "../../components/Elements/PolicyStatuses";
import { ReconciliationOpenList } from "../../components/Lists/ReconciliationOpenList";
import { ReconciliationTransactionReportModel_Resp, ReconciliationTransactionReportModel } from "../../models/TaxTransactionReportModel";
import { MenuDropdown } from "../../components/Elements/MenuDropdown";
import formatPrice from "../../helper/currencyFormatter";
import FilerNotes from "../../components/Elements/filerNotes";
import { Form, Formik } from "formik";
import { FMTextField } from "../../components/Elements/input";

const initValue: getCompletedFilings__Resp = {
  getCompletedFilings_Respdata: [],
  totalCount: 0
}

interface newProp {
  handleClose: () => void;
  open: boolean;
}
export enum FMTabs {
  OutStandingFiling = 8,
  ReadyToFiling = 6,
  CompletedFiling = 5
}

export interface ModelForFilters {
  agency?: string;
  invoiceNumber ? : string
  insuredName?: string;
  policyNumber?: string;
  client?: string;
  taxAmt?: string;
  homeState?: string;
  transactionTypeName?: string;
  invoiceDate?: string;
  effectiveDate?: string;
  policyExpDate?: string;
  dateFiled?: string;
  grossPremium?: string;
  status?: string;
  customerpolicyid: number;
  pageSize?: number;
  pageNumber?: number; 
  field?: string; 
  sort?: string;
  dueDate? : string;
  stateId?: number;
  customerId?: number;
}
interface StatusUpdateProp {
  // selectedRows: ReconciliationTransactionReportModel[] | null;
  paymentPeriod?: string;
  isPaid: boolean;
}

export function RemovedQueue() {

  const value = window.location.pathname.includes('FillingQueue');
  const { user } = useAuth();
  const token = user?.token;
  const [loading, setloading] = React.useState(false);
  const [newComponentDatawithCount, setNewComponentData] = useState<getCompletedFilings__Resp>(initValue)

  const [componentData, setcomponentData] = useState([]);
  const [stateId, setstateId] = useState(0);

  const [PolNumber, setpolNumber] = useState("");
  const [insuredName, setinsuredName] = useState("");

  const [effectiveDateEnd, seteffectiveDateEnd] = useState("");
  const [effectiveDateStart, seteffectiveDateStart] = useState("");
  const params = useParams();

  const PloicyNum = React.useRef("");
  const encodedPolicyNumber = encodeURIComponent(PloicyNum.current);
  const insName = React.useRef("");
  const effectiveDateEd = React.useRef("");
  const effectiveDateSt = React.useRef("");
  const stateData = React.useRef(0);
  const customerData = React.useRef(0);
  const [customerId, setCustomerId] = useState(0);
  const [getCustomerPolicyIds, setCustomerPolicyIds] = React.useState<number[]>([]);
  const [_currentPage, setCurrentPage] = React.useState(1);
  const [_pageSize, setPageSize] = React.useState(50);
  const [totalCount, setTotalCount] = React.useState(0);
  const sortModelRef = useRef<GridSortModel>([]);
  const [openPaidDialog, setOpenPaidDialog] = useState(false);

  var firstDataCount = 0
  const initValue1: ReconciliationTransactionReportModel_Resp = {
    getData_Resp: [],
    totalCount: 0
  }
  const [ReportModule, setReportModule] = useState<ReconciliationTransactionReportModel_Resp>(initValue1)
  const [selectedRows, setSelectedRows] = useState<NewCompletedFilingsModel[] | null>([]);
  const [selectedRowsRecon, setSelectedRowsRecon] = useState<ReconciliationTransactionReportModel[] | null>([]);
  const [currentPolicySubStatus, setCurrentPolicySubStatus] = useState<string>('');
  const [openNotesDialog, setOpenNotesDialog] = useState<string>('');
  
  const [editableSelectedRows, seteditableSelectedRows] = useState<NewCompletedFilingsModel[]>();
  const [openPolicyDueDateNullDialog, setOpenPolicyDueDateNullDialog] = useState(false);
  const [CustPolicyNullDueDateData, SetCustPolicyNullDueDateData] = React.useState<any>();
  const filteredSelectedRows: NewCompletedFilingsModel[] | null = selectedRowsRecon
  ?.filter((item): item is NewCompletedFilingsModel => item.customerpolicyid !== undefined) ?? null;

  interface dataParams {
    customerPolicyId: number
  }
  const initCustValue: dataParams = {
    customerPolicyId: 0
  }

  const getCustomerPolicyDetails = async (customerPolicyId: number): Promise<string> => {
    try {
      setloading(true);
      const initCustValue = {
        customerPolicyId: customerPolicyId
      };
      var action = 'getPolicySubStatusByCustPolId';
      const response = await fetch(
        config.url.API_URL + `/PolicyDashBoard/${action}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
          body: JSON.stringify(initCustValue)
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const res = await response.json();
      if (res.data !== null) {
        setloading(false);
        return res.data;
      } else {
        setloading(false);
        throw new Error("Response data is null");
      }
    } catch (error) {
      setloading(false);
      throw error;
    }
  };

  const initFilterModel: ModelForFilters = {
    customerpolicyid: 0
  }

  const [queryOptions, setQueryOptions] = React.useState<FMFilters>();

  const debouncedOnFilterChange = debounce((filterModel: GridFilterModel, tabValue: number) => {

    setloading(true);

    const newFilters: FMFilters = {
      items: filterModel.items.map(item => {
        let value = item.value ? item.value.trim() : ""; // Trim value to remove extra spaces
      
        if (item.columnField === 'grossPremium') {
          value = value.replace('$', '').replace(',','').replace('.00',''); 
        }
    
        return {
          columnField: item.columnField,
          value: value
        };
      })
    };

    // Update query options with the new filters
    setQueryOptions(newFilters);
    console.log("completedvalue", newFilters)

    // Check if there are any filters with non-empty values
    const hasValue = newFilters.items.some(item => item.value !== '');

    const fetchData = (filters: FMFilters) => {
        fetchDataForFilter("getAllRemovedTransactionQueueData", _pageSize, _currentPage, filters);
    };

    if (hasValue) {
      console.log("grosspremium", newFilters);

      fetchData(newFilters);
    } else {
      setloading(true);
      // Clear filters if no values are present
      const clearedFilters = { items: [] };
      setQueryOptions(clearedFilters);
      //Fetch data without filters
      setPageSize(50);
      fetchData(newFilters);

    }

    // setloading(false); // Stop loading after fetch call
  }, 1200);
  
  const debouncedOnFilterChangeRecon = debounce((filterModel: GridFilterModel, tabValue: number, pageSize: number, pageNumber: number, field: string, sort?: string) => {

    setloading(true);
    const newFilters: FMFilters = {
      items: filterModel.items.map(item => {
        let value = item.value ? item.value.trim() : ""; // Trim value to remove extra spaces
      
        if (item.columnField === 'grossPremium' || item.columnField === 'taxAmt') {
          value = value.replace('$', '').replace(',','').replace('.00',''); 
          
          console.log("premium",value);
        }
    
        return {
          columnField: item.columnField,
          value: value
        };
      })
    };

    // Update query options with the new filters
    setQueryOptions(newFilters);
    const parseDueDate = (dueDateStr: string): Date => {
        const currentDate = new Date();
        const [month, day] = dueDateStr.split('-').map(Number);
        const year = currentDate.getFullYear();
        return new Date(year, month - 1, day);
    };

    // Check if there are any filters with non-empty values
    const hasValue = newFilters.items.some(item => item.value !== '');


    const fetchData = (tabValue: number, filters: FMFilters) => {
        fetchDataForFilterRecon('getRemovedReconQueueOpenFilterData', _pageSize, _currentPage, filters);
    };

    if (hasValue) {
      console.log("grosspremium", newFilters);

      fetchData(tabValue, newFilters);
    } else {
      setloading(true);
      // Clear filters if no values are present
      const clearedFilters = { items: [] };
      setQueryOptions(clearedFilters);
      //Fetch data without filters
      setPageSize(50);
      fetchData(tabValue, newFilters);

    }

    // setloading(false); // Stop loading after fetch call
  }, 1200);// 1 second debounce delay

  const fetchDataForFilterRecon = (action: string, pageSize: number, pageNumber: number, newFilters?: FMFilters) => {
    setloading(true);
    const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
    if (effectiveDateSt.current != null && effectiveDateSt.current != '' && effectiveDateSt.current != undefined) {
      let start: Date = new Date(effectiveDateSt.current);
      effectiveDateSt.current = start.toLocaleDateString('en-US', options);
    }

    if (effectiveDateEd.current != null && effectiveDateEd.current != '' && effectiveDateEd.current != undefined) {
      let end: Date = new Date(effectiveDateEd.current ?? '');
      effectiveDateEd.current = end.toLocaleDateString('en-US', options);

    }
    initFilterModel.customerId = customerData.current;
    initFilterModel.pageNumber = pageNumber;
    initFilterModel.pageSize = pageSize;
    initFilterModel.field = sortModelRef.current[0]?.field ?? '';
    initFilterModel.sort = sortModelRef.current[0]?.sort ?? '';

    initFilterModel.agency = newFilters?.items.find(m => m.columnField === "agency")?.value;
    initFilterModel.invoiceNumber = newFilters?.items.find(m => m.columnField === "invoiceNumber")?.value;
    initFilterModel.dueDate = newFilters?.items.find(m => m.columnField === "dueDate")?.value;
    initFilterModel.effectiveDate = newFilters?.items.find(m => m.columnField === "policyEffDate")?.value;
    initFilterModel.grossPremium = newFilters?.items.find(m => m.columnField === "grossPremium")?.value;
    initFilterModel.homeState = newFilters?.items.find(m => m.columnField === "homeState")?.value;
    initFilterModel.insuredName = newFilters?.items.find(m => m.columnField === "insuredName")?.value;
    initFilterModel.invoiceDate = newFilters?.items.find(m => m.columnField === "invDate")?.value;
    initFilterModel.policyExpDate = newFilters?.items.find(m => m.columnField === "policyExpDate")?.value;
    initFilterModel.policyNumber = newFilters?.items.find(m => m.columnField === "policyNumber")?.value;
    initFilterModel.transactionTypeName = newFilters?.items.find(m => m.columnField === "transactionType")?.value;
    initFilterModel.status = newFilters?.items.find(m => m.columnField === "status")?.value;
    initFilterModel.client = newFilters?.items.find(m => m.columnField === "client")?.value;
    initFilterModel.taxAmt = newFilters?.items.find(m => m.columnField === "taxAmt")?.value;
    initFilterModel.dateFiled = newFilters?.items.find(m => m.columnField === "dateFiled")?.value;

    

    initFilterModel.policyNumber = isValid(initFilterModel.policyNumber) ? initFilterModel.policyNumber : PloicyNum.current;
    initFilterModel.stateId = isValid(initFilterModel.stateId) ? initFilterModel.stateId : stateData.current;
    initFilterModel.insuredName = isValid(initFilterModel.insuredName) ? initFilterModel.insuredName : insName.current;

    fetch(
      config.url.API_URL +
      `/ReconQueue/${action}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(initFilterModel)
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {

        setloading(false);
        if (responseJson.data.totalCount > 0) {
            setReportModule(responseJson.data);
        } else {

            setReportModule({
                ...initValue,
                getData_Resp: [], // Assuming `initValue` has a `data` property that needs to be an empty array
                totalCount: 0,
            });
        }
      })
      .catch((error) => {
        setloading(false);
      });
  }

  const fetchDataForFilter = (action: string, pageSize: number, pageNumber: number, newFilters?: FMFilters) => {
    setloading(true);
    const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
    if (effectiveDateSt.current != null && effectiveDateSt.current != '' && effectiveDateSt.current != undefined) {
      let start: Date = new Date(effectiveDateSt.current);
      effectiveDateSt.current = start.toLocaleDateString('en-US', options);
    }

    if (effectiveDateEd.current != null && effectiveDateEd.current != '' && effectiveDateEd.current != undefined) {
      let end: Date = new Date(effectiveDateEd.current ?? '');
      effectiveDateEd.current = end.toLocaleDateString('en-US', options);

    }
    initFilterModel.customerId = customerData.current;
    initFilterModel.pageNumber = pageNumber;
    initFilterModel.pageSize = pageSize;
    initFilterModel.field = sortModelRef.current[0]?.field ?? '';
    initFilterModel.sort = sortModelRef.current[0]?.sort ?? '';

    initFilterModel.agency = newFilters?.items.find(m => m.columnField === "agency")?.value;
    initFilterModel.invoiceNumber = newFilters?.items.find(m => m.columnField === "invoiceNumber")?.value;
    initFilterModel.dueDate = newFilters?.items.find(m => m.columnField === "dueDate")?.value;
    initFilterModel.effectiveDate = newFilters?.items.find(m => m.columnField === "effectiveDate")?.value;
    initFilterModel.grossPremium = newFilters?.items.find(m => m.columnField === "grossPremium")?.value;
    initFilterModel.homeState = newFilters?.items.find(m => m.columnField === "homeState")?.value;
    initFilterModel.insuredName = newFilters?.items.find(m => m.columnField === "insuredName")?.value;
    initFilterModel.invoiceDate = newFilters?.items.find(m => m.columnField === "invoiceDate")?.value;
    initFilterModel.policyExpDate = newFilters?.items.find(m => m.columnField === "policyExpDate")?.value;
    initFilterModel.policyNumber = newFilters?.items.find(m => m.columnField === "policyNumber")?.value;
    initFilterModel.transactionTypeName = newFilters?.items.find(m => m.columnField === "transactionTypeName")?.value;
    initFilterModel.status = newFilters?.items.find(m => m.columnField === "status")?.value;

    initFilterModel.policyNumber = isValid(initFilterModel.policyNumber) ? initFilterModel.policyNumber : PloicyNum.current;
    initFilterModel.stateId = isValid(initFilterModel.stateId) ? initFilterModel.stateId : stateData.current;
    initFilterModel.insuredName = isValid(initFilterModel.insuredName) ? initFilterModel.insuredName : insName.current;

    fetch(
      config.url.API_URL +
      `/PolicyDashBoard/${action}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(initFilterModel)
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {

        setloading(false);
        if (responseJson.data != null) {
          if (responseJson.data.totalCount > 0) {
            setNewComponentData(responseJson.data);
          } else {
            setNewComponentData(initValue);
          }
        } else {
          setNewComponentData(initValue);
          setcomponentData([]);
        }
      })
      .catch((error) => {
        setloading(false);
      });
  };

  interface dataExportParams {
    customerPolicyIds: number[],
    option: string
  }
  const initValueExport: dataExportParams = {
    customerPolicyIds: [],
    option: ""
  }
  const getCustomerPolicyListbyTaxType = (action: string, customerPolicyIds: number[], option: string) => {
    setloading(true);
    if (customerPolicyIds.length === 0) {
      customerPolicyIds = getCustomerPolicyIds
    }

    if(customerPolicyIds.length == 0) {
      customerPolicyIds = newComponentDatawithCount.getCompletedFilings_Respdata?.map((m: any) => m.customerpolicyid) || [];
    }

    initValueExport.customerPolicyIds = customerPolicyIds;
    initValueExport.option = option;
    console.time('===getCustomerPolicyListbyTaxType===')
    fetch(
      config.url.API_URL +
      `/PolicyDashBoard/${action}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(initValueExport)
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((res) => {
        console.timeEnd('===getCustomerPolicyListbyTaxType===')
        if (res.data !== null) {
          let totalData: NewCompletedFilingsModel[] = [];
          if(res.data.outstandingFilingsData !== null){
            totalData = [...totalData, ...res.data.outstandingFilingsData]
          }
          if(res.data.completedFilingsData !== null){
            totalData = [...totalData, ...res.data.completedFilingsData]
          }
          if(res.data.closedFilingsData !== null){
            totalData = [...totalData, ...res.data.closedFilingsData]
          }
          if (totalData.length !== 0) handleExport(totalData, option, "ExportFilingsData").then(() => {
            //window.location.reload();
          });
          if (res.data.selectedFilingsData !== null) handleExport(res.data.selectedFilingsData, option, "CustomerPolicyData").then(() => {
            // window.location.reload();
          });
        }
        setloading(false);
      }).then(() => {
        setSelectedRows([]);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  function convertDateFormat(dateStr: string): string | undefined {
    if (!dateStr || isNaN(Date.parse(dateStr))) {
      return undefined;
    }

    return moment.utc(dateStr).format("MM/DD/YYYY");
  }
  

  const handleExport = async (items: NewCompletedFilingsModel[], option: string, fileName: string) => {
    if (items.length === 0) {
      return
    }
    var filterExcelItem = option === "Short" ? excelItemsShort : option === "Detailed" ? excelItemsDetailed : option === "All" ? excelItemsAll : excelItemsShort;
    var filterColumnList = option === "Short" ? columnListShort : option === "Detailed" ? columnListDetailed : option === "All" ? columnListShort : columnListShort;
    fileName = option + '_' + fileName;
    const updates1: { [x: string]: any[] } = {}
    filterExcelItem.forEach(ex => {
      const headerRow = ex.columnOrder.map(idx => filterColumnList[idx].xlsHeader[0])
      updates1[fileName] = [headerRow]
    })

    items.forEach(item => {
      if (item.homeState === "NY" &&
        (item.transactionTypeName === "New Business" || item.transactionTypeName === "Renewal Business") &&
        item.filerNotes === "Filed on binder, need dec page") {
        item.filerNotes = "Need Dec Page";
      }
      const xlsHeaders: string[] = []
      Object.keys(item).map((field) => {
        if (!item[field as keyof NewCompletedFilingsModel]) return
        const column = filterColumnList.find(col => col.field === field)
        if (!column?.xlsHeader) return
        xlsHeaders.push(column.xlsHeader[0])
      })

      // date fromating
      item.effectiveDate = convertDateFormat(item.effectiveDate ?? '');
      item.dateFiled = convertDateFormat(item.dateFiled ?? '');
      item.dateInvoiceAdded = convertDateFormat(item.dateInvoiceAdded ?? '');
      item.invoiceDate = convertDateFormat(item.invoiceDate ?? '');
      item.dueDate = convertDateFormat(item.dueDate ?? '');
      item.policyExpDate = convertDateFormat(item.policyExpDate ?? '');
      item.paymentNoticeDate = convertDateFormat(item.paymentNoticeDate ?? '');
      item.diligentSearchToGMAC = convertDateFormat(item.diligentSearchToGMAC ?? '');
      item.firstRequest = convertDateFormat(item.firstRequest ?? '');
      item.secondRequest = convertDateFormat(item.secondRequest ?? '');
      item.thirdRequest = convertDateFormat(item.thirdRequest ?? '');

      const excelItem = filterExcelItem[0]
      const update1 = excelItem.columnOrder.map(idx => {
        const column = filterColumnList[idx]
        const field = column.field as keyof NewCompletedFilingsModel
        let value = item[field];
        if (
          field === "grossPremium" ||
          field === "taxAmt" ||
          field === "nonAdmittedPremium" ||
          field === "taxableFees" ||
          field === "nonTaxableFees" ||
          field === "taxBalance"
      ) {
        let cleanedValue = value?.replace(/[,\$]/g, "").trim();
        
        // If the value is in parentheses, it's negative, so handle that
        if (cleanedValue?.startsWith("(") && cleanedValue?.endsWith(")")) {
            cleanedValue = "-" + cleanedValue?.slice(1, -1); // Remove parentheses and add a negative sign
        }

        // Convert to number and pass to formatPrice
        const numericValue = parseFloat(cleanedValue);

        // If numericValue is a valid number, format it; otherwise, return the original string
        value = isNaN(numericValue) ? value : numericValue?.toString();
      }
        // const value = item[field];
        // const formattedValue = typeof value === 'string' ? value.replace(/[^0-9.]/g, '').trim() : value;

        // const numericValue = formattedValue ? parseFloat(formattedValue) : 0;
        if (value === undefined || value === null) return
        switch (column.inputType) {
          case 'string':
            return value.toString()
          case 'custom':
            return value ? moment.utc(value as string).startOf('day').toDate() : undefined;
          case 'number':
            return +value
          case 'bool':
            return !!value
          default:
            return value
        }
      })

      updates1[fileName].push(update1)
      //updates1[excelItem.name].push(update1)
    })

    console.log({ updates1 })
    console.timeEnd('===handleExport===')

    filterExcelItem.forEach(excelItem => {
      if (!updates1[fileName] || updates1[fileName].length <= 1) return
      // const timeStamp = moment().format('MM.DD.YYYY')
      writeXlsxFile(updates1[fileName], `${fileName}.xlsx`)
    })
  }

  const initialUserdata = { partnersId: 0, customerName: "" };
  const [Customer, setCustomer] = useState([initialUserdata]);
  const fetchCustomer = () => {
    fetch(config.url.API_URL + "/Partners/getPartners", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setCustomer(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const FMSelectCustomCustomer: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
    const { user } = useAuth();
    const token = user?.token;
    const handleChange = (event: SelectChangeEvent) => {
      setloading(true);
      setCustomerId(Number(event.target.value));
      customerData.current = Number(event.target.value);
      setloading(false);
    };

    return (
      <div>
        <FormControl fullWidth>
          {/* <InputLabel id="test-select-label"></InputLabel> */}
          <Select
            onChange={handleChange}
            value={customerId.toString()}
            id="test-select-label"
            autoWidth
            size="small"
            label={null}
            className="border-round custom-menu-item"
            IconComponent={ExpandMoreIcon}
            displayEmpty
          >
            <MenuItem key={0} value={0} className="custom-menu-item">
              Client
            </MenuItem>
            {Customer?.map((item, key) => (
              <MenuItem
                key={key}
                className="custom-menu-item"
                value={item.partnersId}
              >
                {item.customerName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const initialStatedata = { stateId: 0, stateName: "" };
  const [States, setStates] = useState([initialStatedata]);

  const fetchStateData = () => {
    fetch(config.url.API_URL + "/States/getStates", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong");
      })
      .then((responseJson) => {
        // Do something with the response

        setStates(responseJson.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseNotesDialog = () => {
    setOpenNotesDialog('');
  };

  const handleSubmitNotes = (data: { status: string, noteType: string, noteDetail: string, noteUser: string, firstRequestDate: string, secondRequestDate: string, thirdRequestDate: string, suspendedDate: string, noteDate?: string, policySubStatus?: string, dateDeclarationFiled?: string }) => {
    const { firstRequestDate, secondRequestDate, thirdRequestDate, suspendedDate, noteType, noteDetail, noteUser, noteDate, status, policySubStatus, dateDeclarationFiled } = data;
    setOpenNotesDialog('');
    updateAllStatus(status, selectedRowsRecon, noteDetail, noteUser, firstRequestDate, secondRequestDate, thirdRequestDate, suspendedDate, noteDate, policySubStatus, dateDeclarationFiled);
  };

  const getTaxTypeData = async (action: string, customerPolicyIds: number[]) => {
    try {
        //  setloading(true);

        const initValueExport = {
            customerPolicyIds: customerPolicyIds,
            option: ''
        };

        const response = await fetch(
            config.url.API_URL + `/PolicyDashBoard/${action}`,
            {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
                body: JSON.stringify(initValueExport)
            }
        );

        if (!response.ok) {
            throw new Error("Something went wrong");
        }

        const res = await response.json();

        if (res.data !== null) {
            //  setloading(false);
            return res.data;
        } else {
            // setloading(false);
            throw new Error("Response data is null");
        }
    } catch (error) {
        // setloading(false);
        throw error;
    }
  };

  const isDueDateWithinRange = (dueDateStr: string): boolean => {
    const currentDate = new Date();
    const dueDate = parseDueDate(dueDateStr);
    const dateDifference = dueDate.getTime() - currentDate.getTime();
    const dayDifference = dateDifference / (1000 * 3600 * 24);
    return dayDifference >= 0 && dayDifference <= 9;
  };
  const parseDueDate = (dueDateStr: string): Date => {
    const currentDate = new Date();
    const [month, day] = dueDateStr.split('-').map(Number);
    const year = currentDate.getFullYear();
    return new Date(year, month - 1, day);
  };

  var count = 0;
  function updateAllStatus(status: string, selectedRow: any, notes: string = '', filerName: string = '', firstRequestDate: string = '', secondRequestDate: string = '', thirdRequestDate: string = '', suspendedDate: string = '', noteDate?: string, policySubStatus: string = '', dateDeclarationFiled: string = '') {
    setloading(true);
    if (noteDate === null || noteDate === '' || noteDate === undefined) {
        const newDate = new Date().toISOString().slice(0, 10);
        noteDate = moment(newDate).format("MM/DD/YYYY")          
    }
    if (status === "Filed") {
        var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];
        if (count === 0) {

            (async () => {
                try {
                    const customerPolicyIds: number[] = selectedRows
                                            ?.map(m => m.customerpolicyid)
                                            .filter((id): id is number => id !== undefined) ?? [];

                    // Now you can safely pass it to `getTaxTypeData`
                    const response = await getTaxTypeData('getIsPolicyFilingAndReportByCustPolIds', customerPolicyIds);

                    if (response != null && response.length > 0) {
                        const dueDateNotValid: NewCompletedFilingsModel[] = []; // where due date is not prior 10 days of due date
                        response.forEach((e: getPolicyFilingAndReport) => {
                            if (e.report == true) {
                                var newData = selectedRows?.find(m => m.customerpolicyid == e.customerPolicyId);
                                if (!isDueDateWithinRange(newData?.dueDate) && newData !== undefined && newData.customerpolicyid !== undefined) {
                                    dueDateNotValid.push(newData as NewCompletedFilingsModel);
                                }
                            }
                        });

                        const DueDateNotValid = new Set(dueDateNotValid.map(item => item.customerpolicyid));
                        const filteredSelectedIds: NewCompletedFilingsModel[] | undefined = selectedRows
                            ?.filter((item): item is NewCompletedFilingsModel => 
                                item.customerpolicyid !== undefined && !DueDateNotValid.has(item.customerpolicyid)
                            );
                        console.log("filteredSelectedIds :", filteredSelectedIds);
                        count++;
                        if (dueDateNotValid.length > 0) {
                            seteditableSelectedRows(filteredSelectedIds)
                            SetCustPolicyNullDueDateData(dueDateNotValid);
                            setOpenPolicyDueDateNullDialog(true);
                        }
                        else {
                            SetCustPolicyNullDueDateData(null);
                            setOpenPolicyDueDateNullDialog(false);
                            seteditableSelectedRows(undefined);
                            updateAllStatus("Filed", filteredSelectedIds, notes, filerName, firstRequestDate, secondRequestDate, thirdRequestDate, suspendedDate, noteDate, policySubStatus, dateDeclarationFiled);
                        }
                    }
                    setloading(false);
                    return;
                } catch (error) {
                    console.error("Error fetching tax type data:", error);
                    return;
                }
            })();
            return;
        }
    }
    setOpenPolicyDueDateNullDialog(false);

    fetch(config.url.API_URL + `/PolicyDashBoard/updateCustomerPolicyStatus?status=${status}&&notes=${notes}&&filerName=${user?.userFirstName + ' ' + user?.userlastName}&&filerDate=${noteDate}&&firstRequestDate=${firstRequestDate}&&secondRequestDate=${secondRequestDate}&&thirdRequestDate=${thirdRequestDate}&&suspendedDate=${suspendedDate}&&policySubStatus=${policySubStatus}&&dateDeclarationFiled=${dateDeclarationFiled}`, {
        method: "POST",
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(selectedRow)
    })
        .then((response) => {
            setloading(false);
            if (response.ok) {
                return response.json();
            }
            throw new Error("Something went wrong");
        })
        .then((res) => {
            setloading(false);
            if (res !== null && res !== undefined) {
                 Swal.fire({
                    title: res.data,
                    text: res.message,
                    iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                    confirmButtonColor: '#02A4E3',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn-radius',
                        cancelButton: 'btn-radius',
                        popup: 'card-radius'
                    }
                })
                    .then((result) => {

                        setSelectedRows([]);
                        window.location.reload();
                    })
            }
        })
        .catch((error) => {
            console.log(error);
            setloading(false);
        });
  }

  const FMSelectStateCustom: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
    const { user } = useAuth();
    const token = user?.token;
    const handleChange = (event: SelectChangeEvent) => {
      setloading(true);
      setstateId(Number(event.target.value));
      stateData.current = Number(event.target.value);
      setloading(false);
    };
    return (
      <FormControl fullWidth>
        <Select
          onChange={handleChange}
          value={stateId.toString()}
          size="small"
          id="test-select-label"
          // autoWidth
          label={null}
          className="border-round custom-menu-item"
          IconComponent={ExpandMoreIcon}
          displayEmpty
        >
          <MenuItem value={"0"} className="custom-menu-item">Home State</MenuItem>
          {States?.map((state, key) => (
            <MenuItem
              key={key}
              value={state.stateId}
              className="custom-menu-item"
            >
              {state.stateName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const [tax, setTax] = useState("");
  const taxData = React.useRef("");
  const [TaxandFees, setTaxandFees] = useState<string[]>([""]);
  const [statuses, setstatus] = useState("");
  const statusData = React.useRef("");
  const statusList = [
    'Not Started',
    'Waiting on Client',
    'Ready to File',
    'Filed',
    'Pending Autofile',
    'Filed - Binder'
  ];
  if (user?.userRoles.split(",").includes("Super Admin")) {
    statusList.push('Removed');
  }


const fetchTaxFeeName = () => {
  fetch(config.url.API_URL + `/PolicyFilingType/GetAllTaxandFeeName`, {
      method: "GET",
      headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      }),
  })
      .then((response) => {
          if (response.ok) {
              return response.json();
          }
          throw new Error("Something went wrong");
      })
      .then((responseJson) => {
          if (responseJson.data != null) {
              setTaxandFees(responseJson.data);
          }

          console.log("TaxandFees", responseJson.data);
      })
      .catch((error) => {
          console.log(error);
      });
};

  useEffect(() => {
    setcomponentData([]);
    fetchStateData();
    fetchCustomer();
    if (value) {
      fetchDataForFilter("getAllRemovedTransactionQueueData", _pageSize, _currentPage, queryOptions);
    }
    else {
      fetchTaxFeeName();
      fetchDataSorting(_pageSize, _currentPage, '', '');
    }
  }, []);

  const FMSelectTaxAndFeesCustom: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
      const handleTaxChange = (event: SelectChangeEvent<string>) => {
          const selectedTax = event.target.value;
          setloading(true); // Correctly use setLoading
          setTax(selectedTax);
          taxData.current = selectedTax;
          console.log('taxcurrentdata', taxData.current);
          setloading(false);
      };

      return (
          <div>
              <FormControl fullWidth>
                  <Select
                      onChange={handleTaxChange}
                      value={tax}
                      id="test-select-label"
                      autoWidth
                      size="small"
                      label={null}
                      className="border-round custom-menu-item"
                      IconComponent={ExpandMoreIcon}
                      displayEmpty
                  >
                      <MenuItem key={""} value={""} className="custom-menu-item">
                          Tax Type
                      </MenuItem>
                      {TaxandFees?.map((taxdata, key) => (
                          <MenuItem
                              key={key}
                              className="custom-menu-item"
                              value={taxdata}
                          >
                              {taxdata}
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
          </div>
      );
  };

  const FMSelectStatus: React.FC<SelectProps> = ({
    variant,
    ...props
  }) => {
    const handleStatusChange = (event: SelectChangeEvent<string>) => {
        const selectedStatus = event.target.value;
        setloading(true);
        setstatus(selectedStatus);
        statusData.current = selectedStatus; // Update the ref
        console.log('Current Status Data:', statusData.current);
        setloading(false);
    };

    return (
        <div>
            <FormControl fullWidth>
                <Select
                    onChange={handleStatusChange}
                    value={statuses} // Ensure this matches the state
                    id="status-select"
                    autoWidth
                    size="small"
                    label={null}
                    className="border-round custom-menu-item"
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                >
                    <MenuItem key={""} value={""} className="custom-menu-item">
                        Status
                    </MenuItem>
                    {statusList.map((item, key) => (
                        <MenuItem
                            key={key}
                            className="custom-menu-item"
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

  function polnumberchange(e: React.ChangeEvent<HTMLInputElement>) {
    setpolNumber(e.target.value);
    PloicyNum.current = encodeURIComponent(e.target.value);
  }

  function insurednameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setinsuredName(e.target.value);
    insName.current = e.target.value;
  }

  function RunSearch() {
    if (value) {
      fetchDataForFilter("getAllRemovedTransactionQueueData", _pageSize, _currentPage, queryOptions)
    } else {
      fetchDataSorting(_pageSize, _currentPage, '', '');
    }
  }

  function ClearSearch() {
    setloading(true);
    seteffectiveDateStart("");
    seteffectiveDateEnd("");
    setpolNumber("");
    setinsuredName("");
    setCustomerId(0);
    setstateId(0);
    setstatus("");
    setTax("");
    setloading(false);

    PloicyNum.current = "";
    insName.current = "";
    stateData.current = 0;
    customerData.current = 0;
    effectiveDateEd.current = "";
    effectiveDateSt.current = "";

    if (value) {
      fetchDataForFilter("getAllRemovedTransactionQueueData", _pageSize, _currentPage, queryOptions);
    }
    else {
      fetchDataForFilter("getClosedFilingsFilterData", _pageSize, _currentPage, queryOptions);
    }
  }
  const fetchDataSorting = (pageSize: number, pageNumber: number, field: string, sort?: string) => {
    setloading(true);
    console.log({ pageSize, pageNumber, field, sort });
    console.log("refreshdata.");
    // action = 'getAllTaxTransactionFilingsData';
    const selectedValuesArray = ['']//Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
    var customerIds = selectedValuesArray.join(',');
    const url = `${config.url.API_URL}/ReconQueue/getRemovedReconQueueData?userId=${user?.userId}&policyNumber=${PloicyNum.current}
    &stateId=${stateData.current}&effectiveDateStart=${effectiveDateSt.current}&effectiveDateEnd=${effectiveDateEd.current}
    &taxName=${encodeURIComponent(taxData.current)}
    &status=${encodeURIComponent(statusData.current)}
    &insuredName=${insName.current}
    &customerId=${customerData.current}
    &customerIds=${customerIds}
    &pageSize=${pageSize}
    &pageNumber=${pageNumber}
    &field=${field}&sort=${sort}`

    fetch(url, {
        method: "GET",
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }),
    })
        .then((response) => {
            setloading(false);
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong');
        })
        .then((res) => {
          setloading(false);
          console.log("reportlistdata", res.data);

          if (res.data.totalCount > 0) {
              setReportModule(res.data);
          } else {

              setReportModule({
                  ...initValue,
                  getData_Resp: [], // Assuming `initValue` has a `data` property that needs to be an empty array
                  totalCount: 0,
              });
          }
      })
      .catch((error) => {
          setloading(false);
          console.log(error);
      });
  };

  function checkSelectedRowsForNY(): boolean {
    if (selectedRows?.find(x => x.homeState === 'NY')) {
        if (selectedRows.find(x => x.homeState !== 'NY')) {
            Swal.fire({
                title: 'Warning!',
                text: "Please select filings either specifically for New York or those that exclude New York, as there is a special rule for changing the status in New York.",
                iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                // showCancelButton: true,
                confirmButtonColor: '#02A4E3',
                // cancelButtonColor: '#d33',
                // confirmButtonText: 'Proceed',
                customClass: {
                    confirmButton: 'btn-radius',
                    // cancelButton: 'btn-radius',
                    popup: 'card-radius'
                }
            })
                .then((result) => {

                });
            return false;
        }


    }
    return true;
  }

  const handleOpenNotesDialog = (status: string) => {
    setOpenNotesDialog(status);
  };

  function updateStatus(status: string, selectedRow: any) {
    setloading(true);

    fetch(config.url.API_URL + `/PolicyDashBoard/updateCustomerPolicyStatus?status=${status}&&filerName=${user?.userFirstName + ' ' + user?.userlastName}`, {
        method: "POST",
        headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(selectedRow)
    }).then((response) => {
        setloading(false);
        if (response.ok) {
            return response.json();
        }
        throw new Error("Something went wrong");
    }).then((res) => {
        setloading(false);
        if (res !== null && res !== undefined) {
            Swal.fire({
                title: res.data,
                text: res.message,
                iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                confirmButtonColor: '#02A4E3',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn-radius',
                    cancelButton: 'btn-radius',
                    popup: 'card-radius'
                }
            }).then((result) => {
                window.location.reload();
            })
        }
    }).catch((error) => {
        console.log(error);
        setloading(false);
    });
  }

  function handleStatus(status: string) {
    if (!checkSelectedRowsForNY()) {
        return;
    }
    setCurrentPolicySubStatus('');
    const isNewYorkFiling = selectedRows && selectedRows?.length === 1 && selectedRows[0].homeState === 'NY' && (selectedRows[0].transactionTypeName === 'New Business' || selectedRows[0].transactionTypeName === 'Renewal Business')
    console.log("status------------", status);
    // debugger;
    if (status === 'Waiting on Client' || (status === 'Filed' && isNewYorkFiling)) {
        (async () => {
            const customerPolicyId = selectedRows && selectedRows[0]?.customerpolicyid !== undefined
                ? selectedRows[0].customerpolicyid
                : 0;
            const response = await getCustomerPolicyDetails(customerPolicyId);
            // debugger;
            setCurrentPolicySubStatus(response);
            handleOpenNotesDialog(status);

        })();
    }
    else if (selectedRows?.find(m => m.homeState === "NY") && selectedRows.length !== 1 && status === "Filed") {
        Swal.fire({
            title: "",
            text: "Please select one filing for 'New York' state",
            icon: 'warning',
            confirmButtonText: "OK",
        });
        return;
    }
    else {
        Swal.fire({
            title: 'Confirmation!',
            text: "Do you want to update customer policy's status as " + status + "?",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setloading(true);
                    if (status !== undefined && status !== '' && status !== null) {
                        setCurrentPolicySubStatus('');
                        updateStatus(status, selectedRowsRecon);
                    }
                }
            });
        setloading(false);
    }
  }

  async function fetchExportData(action: string, customers: string | string[], pageSize: number, pageNumber: number, selectedRows: any) {

    setloading(true);
    var MultiplePolicyIds: string = selectedRows?.map((x: { customerpolicyid: string }) => x.customerpolicyid).join(",") ?? "";
    const response = await fetch(
        config.url.API_URL +
        `/ReconQueue/${action}?userId=${user?.userId}&&policyNumber=${PloicyNum.current}
        &stateId=${stateData.current}&effectiveDateStart=${effectiveDateSt.current}&effectiveDateEnd=${effectiveDateEd.current}
        &taxName=${encodeURIComponent(taxData.current)}&status=${encodeURIComponent(statusData.current)}
        &insuredName=${insName.current}&customerId=${customerData.current}&&customerIds=${MultiplePolicyIds}&&pageSize=${pageSize}&&pageNumber=${pageNumber}`,
        {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        }
    )
        .then((response) => {
            setloading(false)
            if (response.ok) {
                return response.json();
            }
            throw new Error('Something went wrong');
        })
        .then((res) => {
            setloading(false)
            if (res.data.totalCount > 0) {

                if (pageSize <= 100) {
                    setReportModule(res.data)
                }


                return res.data.getData_Resp;
            }

        }).catch((error) => {
            setloading(false)
        });
    return response;
  }

  const onExportXlsx = (values: ReconciliationTransactionReportModel[]) => {
    setloading(true);
    if (values?.length === 0) {
        setloading(false);
        return;
    }
    const fieldsToExport: (keyof ReconciliationTransactionReportModel)[] = [
        'invoiceNumber', 'insuredName', 'policyNumber',
        'homeState', 'taxType', 'transactionType', 'invDate',
        'policyEffDate', 'policyExpDate', 'grossPremium', 'taxAmt', 'client', 'clientDiv'
    ];
    const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

    const formattedValues = values.map(item => {
        // Helper function to clean and format the value
        const cleanAndFormatValue = (value: string | null | undefined): string => {
            if (value == null || value === '') return ""; // Handle empty, null, or undefined values
        
            // Trim any spaces and remove the dollar sign and commas
            let cleanedValue = value.replace(/[,\$]/g, "").trim();
        
            // If the value is in parentheses, treat it as negative
            if (cleanedValue.startsWith("(") && cleanedValue.endsWith(")")) {
                cleanedValue = "-" + cleanedValue.slice(1, -1);  // Remove parentheses and add negative sign
            }
        
            // Convert to a number and format if valid
            const numericValue = parseFloat(cleanedValue);
            return isNaN(numericValue) ? value : `${formatPrice(numericValue.toString())}`;
        };
    
        // Apply formatting for taxAmt and grossPremium
        item.taxAmt = cleanAndFormatValue(item.taxAmt);
        item.grossPremium = cleanAndFormatValue(item.grossPremium);
    
        // Format dates if they are not null or empty
        const invDate = item.invDate && item.invDate !== '1900-01-01T00:00:00' ? new Date(item.invDate) : "";
        const policyEffDate = item.policyEffDate && item.policyEffDate !== '1900-01-01T00:00:00' ? new Date(item.policyEffDate) : "";
        const policyExpDate = item.policyExpDate && item.policyExpDate !== '1900-01-01T00:00:00' ? new Date(item.policyExpDate) : "";
        const dateFiled = item.dateFiled && item.dateFiled !== '1900-01-01T00:00:00' ? new Date(item.dateFiled) : "";
    
        const update: (string | number | Date | undefined)[] = fieldsToExport.map((key: keyof ReconciliationTransactionReportModel) => {
            if (key === 'invDate') return invDate;
            if (key === 'policyEffDate') return policyEffDate;
            if (key === 'policyExpDate') return policyExpDate;
            if (key === 'dateFiled') return dateFiled;
            return item[key];
        });
    
        return update;
    });
    
    
    const headers = fieldsToExport.map(field => capitalizeFirstLetter(field));

    const updates = [
        headers,
        ...formattedValues
    ];

    writeXlsxFile(updates, "ReconciliationQueue");
    setloading(false);
  };

  const handleExportXlsxNew = async (val: ReconciliationTransactionReportModel[]) => {
    try {
        setloading(true);

        // console.log('selected Rows', selectedRowsRecon);
        // const response = await fetchExportData('getRemovedReconQueueData', [''], ReportModule.totalCount, 1, selectedRowsRecon);
        // console.log('respose',response);
        
        // let values = response
        let arr: ReconciliationTransactionReportModel[] = [];
        let response;
        if (selectedRowsRecon && selectedRowsRecon.length > 0) {
            arr.push(...selectedRowsRecon); // Spread the items only if it's not null
        } else {
            // If selectedRowsRecon is null, make the API request without it and assign the response to `arr`
            response = await fetchExportData('getRemovedReconQueueData', [''], ReportModule.totalCount, 1, selectedRowsRecon);
            console.log('response', response);
            if (response) {
                arr = response; // Assuming response is an array of ReconciliationTransactionReportModel
            }
        }

        let values = arr
        if (val && val?.length > 0) {
            values = val
        }
        if (values?.length <= 0) {
            alert('Please upload a xlsx file')
            return
        }
        onExportXlsx(values);
        setSelectedRows([]);
        fetchDataSorting(_pageSize, _currentPage, '', '');
        setloading(false);
    } catch (error) {
        setloading(false);
        console.error("Error occurred:", error);
    }
  }

  const handleClosePaidDialog = () => {
    setOpenPaidDialog(false);
  };

  const updateStatusChange = ({isPaid, paymentPeriod}: StatusUpdateProp) => {
    fetch(
        config.url.API_URL + `/ReconQueue/updateReconPaidStatus`,
        {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }),
            body: JSON.stringify({
                selectedRows: selectedRowsRecon,
                isPaid,
                paymentPeriod,
            })
        }
    ).then((response) => {
        if (response.ok) {
            return response.json();
        }
        throw new Error('Something went wrong');
    }).then((res) => {
        if (res.resp === 1) {
            Swal.fire({
                title: res.message,
                text: "",
                icon: "warning",
                confirmButtonText: "OK",
            }).then((result) => {
              window.location.reload();
          });
            console.log("response", res);
        } else if (res.resp === 0) {

            Swal.fire({
                title: "Data Successfully saved",
                text: "",
                icon: "success",
                confirmButtonText: "OK",
            }).then((result) => {
              window.location.reload();
            });
            setSelectedRows([]);
            handleClosePaidDialog();
            fetchDataSorting(_pageSize, _currentPage, '', '');

        } else {
            Swal.fire({
                title: "Unexpected response",
                text: "The response code was not recognized.",
                icon: "warning",
                confirmButtonText: "OK",
            }).then((result) => {
              window.location.reload();
            });

            console.log("response", res);
        }
    }).catch((error) => {
        Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
        });
        console.error('Error:', error);
    });
  }

  const ShowPaymentPeriod = ({ open, handleClose }: newProp) => {
    const initValuePaid: StatusUpdateProp = {
        isPaid: true,
        paymentPeriod: '',
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle className='card-title'>Payment Period</DialogTitle>
            <React.Fragment>
                <Formik
                    initialValues={initValuePaid}
                    onSubmit={(data, setError) => {
                        updateStatusChange(data)
                    }}
                >
                    {({ values, isSubmitting, setErrors }) => (
                        <Form className='main-form'>

                            <DialogContent>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%', minWidth: '500px' }}>
                                        <FMTextField
                                            name="paymentPeriod"
                                            label={null}
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ marginTop: 2 }}
                                            multiline
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <button className='btn-2 btn d-flex btn-primary search-btn' style={{ minWidth: '125px' }} type='button' onClick={handleClose}>Cancel</button>
                                <button className='btn btn-primary active-btn-blue' type='submit' disabled={isSubmitting}>Submit</button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        </Dialog>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="card-title">
          <span className="d-flex">Removed Transaction Queue</span>
        </div>
      </div>
      <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
        {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
      </div>
  
      <Box sx={{}}>
        <Grid
          container
          spacing={2}
          className="filter-grid"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xl={2} lg={4} sm={6} xs={12}>
            {user?.userRoles.split(",").includes("Super Admin")
              || user?.userRoles.split(",").includes("MISC State Reporter")
              || user?.userRoles.split(",").includes("MISC Filer")
              ? (
                <FMSelectCustomCustomer name="customer" />
              ) : ""}
          </Grid>
          <Grid item xl={2} lg={4} sm={6} xs={12} >
            <FMSelectStateCustom name="state" />
          </Grid>
          { value ? 
            (
              <>
                <Grid item xl={2} lg={4} sm={6} xs={12} className=" select-input-border">
                  <TextField
                    name="Insured Name"
                    size="small"
                    className="select-input-border text-input-box"
                    autoFocus
                    value={insuredName}
                    label="Insured Name" onChange={insurednameChange}
                    type="text"
                    fullWidth
                    variant="outlined"
                    id="insuredName"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xl={2} lg={4} sm={6} xs={12}>
                  <FMSelectTaxAndFeesCustom name="taxName" />
                </Grid>
              </>
            )}
          <Grid item xl={2} lg={4} sm={6} xs={12} className=" select-input-border">
            <TextField
              value={PolNumber}
              name="PolicyNumber"
              size="small"
              autoFocus
              className="select-input-border text-input-box"
              label="Policy Number"
              onChange={polnumberchange}
              type="text"
              fullWidth
              variant="outlined"
              id="PolicyNumber"
            />
          </Grid>

          <Grid item xl={0.7} sm={12} xs={12} >
            <FormControl fullWidth>
              <div>
                <button
                  type="button"
                  style={{}}
                  onClick={() => {
                    RunSearch();
                  }}
                  className=" active-btn1-blue-tabs"
                >
                  Search
                </button></div>
            </FormControl>
          </Grid>

          <Grid item xl={0.7} sm={12} xs={12} alignItems="center">
            <FormControl fullWidth>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    ClearSearch();
                  }}
                  className=" btn-clear-refresh btn"
                >
                  Clear
                </button></div>
            </FormControl>
          </Grid>

          <Grid item xl={0.7} sm={12} xs={12} alignItems="center">
            <FormControl fullWidth>
              <div >
                <button
                  type="button"
                  onClick={() => {
                    window.location.reload();
                  }}
                  className=" btn-clear-refresh btn"
                >
                  Refresh
                </button>
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {
        !value && (
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="card-title"></div>
            <div className="d-flex align-items-center mt-2" >
                {(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC State Reporter")) && (selectedRowsRecon !== null && selectedRowsRecon?.length > 0) ?
                    <button
                        className=" ms-2 active-btn-red "
                        onClick={() => setOpenPaidDialog(true)}
                        title="Paid" >
                          Paid
                    </button>
                    : ""
                }
                {selectedRowsRecon?.length !== 0 && selectedRowsRecon?.length !== undefined ?
                    <div className="dropdowm-class ms-2" style={{ zIndex: "9" }} >
                        <MenuDropdown
                            menuTitle={'Status'}
                            items={statusList}
                            onItemClick={handleStatus} />
                    </div>
                    : ''}
                <button
                    className=" ms-2  active-btn-blue-export-12  "
                    onClick={() => { handleExportXlsxNew([]) }}
                    title="Export" >
                    Export
                </button>
            </div>
          </div>
        )
      }
      <Box>
        { value &&
          <RemovedFilingQueue
            NewCompletedFilingsCL={newComponentDatawithCount}
            getCustomerPolicyListbyTaxType={getCustomerPolicyListbyTaxType}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            getCustomerPolicyDetails={getCustomerPolicyDetails}
            setCurrentPage={setCurrentPage}
            _currentPage={_currentPage}
            setPageSize={setPageSize}
            _pageSize={_pageSize}
            fetchDataForFilter={fetchDataForFilter}
            queryOptions={queryOptions}
            debouncedOnFilterChange={debouncedOnFilterChange}
            sortModelRef={sortModelRef}
          />
        }
      </Box>
        { !value &&
          <RemovedReconciliationQueue
            ReportModuleCL={ReportModule}
            fetchDataMain={fetchDataSorting}
            setSelectedRows={setSelectedRowsRecon}
            selectedRows={selectedRowsRecon}
            setCurrentPage={setCurrentPage}
            _currentPage={_currentPage}
            setPageSize={setPageSize}
            _pageSize={_pageSize}
            debouncedOnFilterChange={debouncedOnFilterChangeRecon}
          />
        }

        {openNotesDialog && <FilerNotes
          open={!!openNotesDialog}
          handleClose={handleCloseNotesDialog}
          handleSubmit={handleSubmitNotes}
          selectedRows={filteredSelectedRows}
          currentStatus={openNotesDialog}
          currentPolicySubStatus={currentPolicySubStatus}
        />}
        <ShowPaymentPeriod handleClose={handleClosePaidDialog} open={openPaidDialog} />
    </LocalizationProvider>
  );
}
