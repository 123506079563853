
import Modal from "react-bootstrap/Modal";

interface PopUpProps {
  title: string;
  show: boolean;
  handleClose: () => void;
  body: JSX.Element  // Updated type to explicitly define JSX.Element for the body
}

const PopUp = ({ show, handleClose, body, title }: PopUpProps) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body> {/* Dynamically render the body */}
      <Modal.Footer>
        <button
          className="btn-clear-refresh btn"
          style={{ width: "93px" }}
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopUp;
