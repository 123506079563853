import { useEffect, useState } from "react"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Box, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps, TextField } from "@mui/material"
import useAuth from "../context/AuthProvider"
import { config } from "../Constants"
import { ClientBillingReportMonthlyList } from "../components/Lists/ClientBillingReportMonthlyList"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ClientBillingReportAnnualList } from "../components/Lists/ClientBillingReportAnnualList"
import React from "react"
import Swal from "sweetalert2"
import { padding } from "@mui/system"
import { months } from "moment"
import { StateReportsList } from "../components/Lists/StateReportsList"
import { StateReportsBillingModel, StateReportsBillingModel_Resp } from "../models/StateReportsBillingModel"
import { apiClient } from "../helper/api"
import { useParams } from "react-router-dom";
const keyNames: (keyof StateReportsBillingModel)[] = [
  'stateId', 'reportName', 'period', 'license', 'dateFinal', 'isPaymentRequired', 'paymentType', 'isReportRequired', 'licenseMain', 'premiumReport', 'feeDue'
]

export enum StateReportTabs {
  Open = 1,
  Closed = 2,
}
export const StateReportsBilling = () => {

  const { user } = useAuth()
  const token = user?.token

  const initValue: StateReportsBillingModel_Resp = {
    items: [],
    totalCount: 0,
    pageCount: 0,
    pageSize: 25,
    currentPage: 0,
  }
  const [itemList, setItemList] = useState<StateReportsBillingModel[]>(initValue.items)
  const [currentPage, setCurrentPage] = useState(initValue.currentPage)
  const [pageSize, setPageSize] = useState(initValue.pageSize)
  const [loading, setloading] = useState(false);

  const fetchCBR = async ({ carrierId, pageSize, currentPage }: { carrierId: string, pageSize: number, currentPage: number }) => {
    setloading(true)

    const res = await apiClient(`/ReportModule/ClientBillingReportView?pageSize=${0}&&currentPage=${0}`, {
      method: "GET",
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }),
    })

    // Do something with the response
    if (!res?.data?.items) return
    const updates = res.data.items.map((item: StateReportsBillingModel, index: number) => ({
      rowNum: (index + 1).toString(),
      ...item,
    }))

    setItemList(updates)
    console.log('fetch success: ', { updates })
    setloading(false)

  }

  const [partnerName, setPartnerName] = useState<string>("");
  const fetchData = (action: string, pageSize: number, currentPage: number, period: string, PartnerId: string, month: string, year: string, columnField: string, value: string, field: string, sort: string, operators: string, completedPage: number) => {
    setloading(true);
    const params = new URLSearchParams();

    params.append('pageSize', pageSize.toString() || '');
    params.append('currentPage', currentPage.toString() || '');
    params.append('period', period || '');
    params.append('PartnerId', PartnerId || '');
    if (period === "Monthly") {
      params.append('month', month || '1');
    } else {
      params.append('month', '0');
    }
    params.append('year', year || '');
    params.append('fieldName', columnField || '');
    params.append('value', value || '');
    params.append('field', field || '');
    params.append('sort', sort || '');
    params.append('operators', operators || '');
    params.append('completedPage',completedPage.toString() )
    apiClient(`/ClientBilling/${action}?${params.toString()}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then(responseJson => {
        if (!responseJson) return
        if (responseJson.data && responseJson.data.items?.length > 0) {
          setItemList(responseJson.data.items);
          if (responseJson.data.items.length !== 0) {
            setPartnerName(responseJson.data.items[0].partnerName);
          }
          setloading(false);
          console.log("alldata", responseJson.data);
        } else {
          setItemList([]);
          setloading(false);
        }
      })
      .finally(() => {
        // setItemList([]);
        setloading(false);
      });
  };
  const [selectedValue, setSelectedValue] = useState<string>("Monthly");

  const [selectMonthValue, setSelectedMonthValue] = useState<string>("1");
  const [sortModel, setSortModel] = useState([{ field: "", sort: "" }]);
  const [filterModel, setFilterModel] = useState({ items: [{ columnField: "", value: "", operatorValue: "" }] });

  const [yearValue, setYearValue] = useState(new Date().getFullYear().toString());
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [selectedButton, setSelectedButton] = useState<number>(1);
  const [completedPage, setCompletedPage] = useState<number>(1);

  const deleteOTRData = async (items: StateReportsBillingModel[]) => {
    console.log("body............", JSON.stringify(items)); // Log the entire array of items

    // Send the array of items in one request
    const result = await apiClient(`/ClientBilling/deleteClientBillingReport`, {
      method: "POST",
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(items), // Send the array of items
    });

    console.log("Delete response:", result);
    setItemList([]);
    // reloadData();
    return result;
  };
  const handleChangetab = async (
    event: React.SyntheticEvent | undefined,
    newValue: number
  ) => {
    console.log("Tab clicked. New Value:", newValue);
      setCompletedPage(newValue)
      console.log("completedPage", completedPage);
      // setloading(true);
      setSelectedButton(newValue);
      const action = "getStateReport";
      const element = document.getElementById('selectedTab');
      
  };

  const getCBR = async () => {
    console.log("getCBR triggered. CompletedPage:", completedPage);
    fetchData('getStateReportBilling', 50, 1, "Monthly", "0", selectMonthValue, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'], filterModel['items'][0]['operatorValue'],selectedButton)


  }

  useEffect(() => {
    console.log("useEffect triggered. CompletedPage:", completedPage);
    getCBR()
    fetchCustomer()
    setSelectedPartner('0');
  }, [completedPage])

  const handlePageChange = (val: number) => {
    setCurrentPage(val)
  }
  const handlePageSizeChange = (val: number) => {
    if (val <= 0) return
    setPageSize(val)
  }

  const handleRemoveItems = async (values: StateReportsBillingModel[]) => {
    console.log({ values })
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      showCancelButton: true,
      confirmButtonColor: '#02A4E3',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete',
      customClass: {
        confirmButton: 'btn-radius',
        cancelButton: 'btn-radius',
        popup: 'card-radius'
      }
    })

    if (result.isConfirmed) {
      await deleteOTRData(values)
      getCBR()
    }
  }

  const handleChange = (event: SelectChangeEvent<string>) => {

    setSelectedValue(event.target.value);
    fetchData("getStateReportBilling", 50, 1, event.target.value, SelectedPartner, selectMonthValue, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'], filterModel['items'][0]['operatorValue'], selectedButton);

  };

  const handleMonthChange = (event: SelectChangeEvent<string>) => {

    setSelectedMonthValue(event.target.value);
    fetchData("getStateReportBilling", 50, 1, selectedValue, SelectedPartner, event.target.value, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'], filterModel['items'][0]['operatorValue'], selectedButton);

  };


  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYearValue(event.target.value);
    const currentYear = new Date().getFullYear();
    let value = parseInt(event.target.value, 10);
    if (value > 1900 && value <= currentYear) {
      fetchData("getStateReportBilling", 50, 1, selectedValue, SelectedPartner, selectMonthValue, event.target.value, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'], filterModel['items'][0]['operatorValue'], selectedButton);
    }
    else {
      setItemList([]);
    }
  };


  const handleSortChange = (newSortModel: any) => {
    setSortModel(newSortModel)
    console.log('filterModel', filterModel);
    console.log('newSortModel', newSortModel);
    console.log("abcdef", filterModel['items'][0]['columnField']);
    fetchData("getStateReportBilling", 50, 1, selectedValue, SelectedPartner, selectMonthValue, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], newSortModel[0]['field'], newSortModel[0]['sort'], filterModel['items'][0]['operatorValue'], selectedButton);
  };

  const handleFilterChange = (newFilterModel: any) => {
    setFilterModel(newFilterModel)
    fetchData("getStateReportBilling", 50, 1, selectedValue, SelectedPartner, selectMonthValue, yearValue, newFilterModel['items'][0]['columnField'], newFilterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'], filterModel['items'][0]['operatorValue'], selectedButton);
  };

  const [SelectedPartner, setSelectedPartner] = React.useState<string>('0');
  const initialUserdata = { partnersId: 0, customerName: "" };
  const [Customer, setCustomer] = useState([initialUserdata]);
  const fetchCustomer = () => {
    apiClient("/Partners/getPartners", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((responseJson) => {
        if (!responseJson) return
        // Do something with the response

        setCustomer(responseJson.data);
      })
  };



  const FMSelectCustomCustomer: React.FC<SelectProps> = ({ variant, ...props }) => {
    const handleChangePartner = (event: SelectChangeEvent) => {
      fetchData("getStateReportBilling", 50, 1, selectedValue, event.target.value, selectMonthValue, yearValue, filterModel['items'][0]['columnField'], filterModel['items'][0]['value'], sortModel[0]['field'], sortModel[0]['sort'], filterModel['items'][0]['operatorValue'], selectedButton);
      setSelectedPartner(event.target.value);
    };
    return (
      <div>
        <FormControl fullWidth>
          <Select
            onChange={handleChangePartner}
            value={SelectedPartner}
            id="test-select-label"
            autoWidth
            size="small"
            label={null}
            className="border-round custom-menu-item"
            IconComponent={ExpandMoreIcon}
            displayEmpty
          >
            <MenuItem key={""} value={"0"}>All</MenuItem>
            {Customer?.map((item, key) => (
              <MenuItem
                key={key}
                className="custom-menu-item"
                value={item.partnersId}
              >
                {item.customerName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };
  function dateHandle() {

  }
  function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="card-title">
          <span className="d-flex">State Reports Billing</span>
        </div>
      </div>
      <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
        {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
      </div>
      <Box sx={{ width: "100%" }}>
        <div className="col-lg-12    ">
          <div
            className=" d-flex  "
            style={{ marginBottom: "28px" }} role="group"
            aria-label="Basic example"
          >
            <React.Fragment>
                        <div className="col-4  text-center ">
                          <button type="button"
                            className={`btn-tab btn ${selectedButton === 1 ? "btn-Selected" : "btn-primary1"
                              }`}
                            {...a11yProps(8)}
                            onClick={() => handleChangetab(undefined, 1)}
                          >
                           Upcoming Report
                          </button>
                        </div>
                        <div className="col-4 text-center">
                            <button
                                type="button"
                                
                                className={`btn-tab btn ${selectedButton === 2 ? "btn-Selected" : "btn-primary"
                                }`}
                                {...a11yProps(5)}
                                onClick={() => handleChangetab(undefined, 2)}
                            >
                                Completed Report
                            </button>
                        </div>
                        <div className="col-4 text-center">
                            <button
                                type="button"
                                
                                className={`btn-tab btn ${selectedButton === 3 ? "btn-Selected" : "btn-primary"
                                }`}
                                {...a11yProps(5)}
                                onClick={() => handleChangetab(undefined, 3)}
                            >
                                Outstanding Report
                            </button>
                        </div>
            </React.Fragment>
          </div>
        </div>

        <Box sx={{ width: "100%" }}>
          <Box>
            <Grid
              container
              spacing={2}
              className="filter-grid"
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item xl={2} lg={4} sm={6} xs={12}>
                {(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC State Reporter")) ? (
                  <FMSelectCustomCustomer name="customer" />
                ) : ""}
              </Grid>
              {/* {completedPage && (
                <>
                  <Grid item xl={2} lg={4} sm={6} xs={12}>
                    <FormControl fullWidth className="main-form-2 ms-3 d-flex">
                      <InputLabel className="" id="month-Label">Select Month</InputLabel>
                      <Select
                        labelId="month-Label"
                        IconComponent={ExpandMoreIcon}
                        label="Select Month"
                        value={selectMonthValue}
                        onChange={handleMonthChange}
                      >
                        <MenuItem value={1}>January</MenuItem>
                        <MenuItem value={2}>February</MenuItem>
                        <MenuItem value={3}>March</MenuItem>
                        <MenuItem value={4}>April</MenuItem>
                        <MenuItem value={5}>May</MenuItem>
                        <MenuItem value={6}>June</MenuItem>
                        <MenuItem value={7}>July</MenuItem>
                        <MenuItem value={8}>August</MenuItem>
                        <MenuItem value={9}>September</MenuItem>
                        <MenuItem value={10}>October</MenuItem>
                        <MenuItem value={11}>November</MenuItem>
                        <MenuItem value={12}>December</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={2} lg={4} sm={6} xs={12}>
                    <FormControl fullWidth className="main-form-3 ms-3 d-flex">
                      <TextField
                        label="Year"
                        type="number"
                        value={yearValue}
                        onChange={handleYearChange}
                        defaultValue={new Date().getFullYear()}
                        InputProps={{
                          style: { height: '2.8rem' } // Change font size here for input text
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              )} */}
              {(completedPage == 2 || completedPage == 3) && (
                <>
                  <Grid item xl={2} lg={4} sm={6} xs={12}>
                    <FormControl fullWidth className="main-form-2 ms-3 d-flex">
                      <InputLabel className="" id="month-Label">Select Month</InputLabel>
                      <Select
                        labelId="month-Label"
                        IconComponent={ExpandMoreIcon}
                        label="Select Month"
                        value={selectMonthValue}
                        onChange={handleMonthChange}
                      >
                        {Array.from({ length: 12 }, (_, index) => {
                          const monthValue = index + 1; // Month value (1-12)
                          const currentMonth = new Date().getMonth() + 1; // Current month (1-12)
                          const currentYear = new Date().getFullYear(); // Current year
                          const isDisabled = parseInt(yearValue, 10) > currentYear || (parseInt(yearValue, 10) === currentYear && monthValue > currentMonth);

                          return (
                            <MenuItem key={monthValue} value={monthValue} disabled={isDisabled}>
                              {new Date(0, index).toLocaleString('default', { month: 'long' })}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xl={2} lg={4} sm={6} xs={12}>
                    <FormControl fullWidth className="main-form-3 ms-3 d-flex">
                      <TextField
                        label="Year"
                        type="number"
                        value={yearValue}
                        onChange={handleYearChange}
                        defaultValue={new Date().getFullYear()}
                        InputProps={{
                          style: { height: '2.8rem' } // Change font size here for input text
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
          <StateReportsList
            listData={itemList}
            onRemoveItems={handleRemoveItems}
            setItemList={setItemList}
            selectedMonth={Number(selectMonthValue)}
            handleFilterChange={handleFilterChange}
            handleSortChange={handleSortChange}
            selectedPeriod={selectedValue}
            selectedYear={yearValue}
            selectedTab={completedPage}
          />
    </LocalizationProvider>
  )
}



