import React, { useEffect, useMemo, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Checkbox
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import useAuth from "../context/AuthProvider";
import { Form, Formik } from "formik";
import Swal from "sweetalert2";
import { apiClient } from "../helper/api";

interface props {
    open: boolean;
    handleClose: () => void;
    customerPolicyId: any;
}

interface RequiredDocument {
    srdmId: number;
    documentName: string;
    isRequired: boolean;
}

export const AddDocument: React.FC<props> = ({ open, handleClose, customerPolicyId }) => {
    const initialValues: {} = {};
    const [loading, setLoading] = useState(false);
    const [itemList, setItemList] = useState<RequiredDocument[]>([])
    const [srdmIds, setSrdmIds] = useState<number[]>([])
    const { user } = useAuth();
    const token = user?.token;

    const [selectedFiles, setSelectedFiles] = useState<File | null>(null);

    useEffect(() => {
        if(open){
            fetchData();
        }
    }, [open])

    const fetchData = () => {
        apiClient(`/customer/getRequiredDocuments?customerPolicyId=${customerPolicyId}&all=0`, {
          method: "GET",
          headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          }),
        })
        .then(responseJson => {
            if (responseJson.data && responseJson.data.length > 0) {
              setItemList(responseJson.data);
              setLoading(false);
            } else {
                setItemList([]);
                setSrdmIds([]);
                setLoading(false);
            }
        })
        .catch(error => {
          setSrdmIds([]);
          setItemList([]);
          setLoading(false);
        });
    };

    const handleFileDrop = (file: File, srdmId: number) => {
        setSelectedFiles(file);
    };

    const handleFileRemove = () => {
        setSrdmIds([]);
        setSelectedFiles(null);
    };

    const handleBoxClose = () => {
        setSelectedFiles(null);
        setItemList([])
        setSrdmIds([]);
        handleClose();
    }

    const handleCheckboxChange = (srdmId: number) => {
        setSrdmIds((prevSrdmIds) => {
            if (prevSrdmIds.includes(srdmId)) {
                return prevSrdmIds.filter((id) => id !== srdmId);
            } else {
                return [...prevSrdmIds, srdmId];
            }
        });
    };
    
    return (
        <Dialog
            open={open}
            onClose={handleBoxClose}
            fullWidth
            maxWidth={false} // Disable default max width
            sx={{
                "& .MuiDialog-paper": {
                    width: "45%", // Set a fixed width for better alignment
                    maxWidth: "100%", // Ensure responsiveness
                    margin: "0 auto", // Center horizontally
                    borderRadius: "8px", // Add rounded corners
                    padding: "20px", // Add padding for spacing
                    textAlign: "center", // Center text
                },
            }}
        >
            <div className="card-body">
                <DialogTitle className="card-title">Upload Documents</DialogTitle>
            </div>
            <React.Fragment>
                <Formik
                    initialValues={initialValues}
                    validateOnChange={true}
                    onSubmit={(data, { setSubmitting }) => {
                        if (!selectedFiles) {
                            Swal.fire("Error", "Please select at least one document.", "error");
                            setSubmitting(false);
                            return;
                        }

                        const formData = new FormData();
                        formData.append('document', selectedFiles);
                        formData.append("CustomerPolicyId", customerPolicyId);
                        formData.append("data", JSON.stringify(data));
                        srdmIds.forEach((id) => {
                            formData.append("srdmIds[]", id.toString());
                        });

                        setLoading(true);
                        apiClient(`/customer/UploadDocuments/`, {
                            method: "POST",
                            headers: new Headers({
                                Authorization: `Bearer ${token}`,
                            }),
                            body: formData,
                        })
                            .then((res) => {
                                if (!res) return;
                                Swal.fire({
                                    title: res.message,
                                    text: res.data,
                                    icon: res.data,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Ok'
                                });
                            })
                            .finally(() => {
                                setSubmitting(false);
                                setLoading(false);
                                handleBoxClose();
                            });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                            <DialogContent>
                                <div className="card-body">
                                    <div className="document-item">
                                        <GenericStyledDropZone
                                            handleFileDrop={handleFileDrop}
                                        />
                                        { selectedFiles &&
                                            <div className="uploaded-files">
                                                <div className="file-item">
                                                    <span className="file-name">
                                                        {selectedFiles?.name}
                                                    </span>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleFileRemove()}
                                                        className="remove-button"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <List
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                        gap: "16px",
                                    }}
                                >
                                    {selectedFiles && itemList.map((item) => (
                                        <ListItem
                                            key={item.srdmId}
                                            onClick={() => handleCheckboxChange(item.srdmId)}
                                            sx={{
                                                flex: "0 1 calc(50% - 8px)", // Each item takes up 50% width minus gap
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "8px 16px",
                                                borderBottom: "1px solid #e0e0e0",
                                                backgroundColor: srdmIds.includes(item.srdmId) ? "#E8F0FE" : "#F9F9F9",
                                                borderRadius: "8px",
                                                cursor: "pointer", // Make the item look clickable
                                                transition: "background-color 0.2s ease",
                                                "&:hover": {
                                                    backgroundColor: "#E8F0FE",
                                                },
                                            }}
                                        >
                                            <Checkbox
                                                checked={srdmIds.includes(item.srdmId)}
                                                sx={{
                                                    padding: "4px",
                                                    pointerEvents: "none", // Prevent the checkbox from blocking the ListItem click
                                                }}
                                            />
                                            <ListItemText
                                                // primary={item.documentName}
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "#162E3C",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                {item.documentName}
                                                {item.isRequired && (
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            marginLeft: "4px",
                                                            fontSize: "16px",
                                                            fontWeight: "600"
                                                        }}
                                                    >
                                                        *
                                                    </span>
                                                )}
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                                <div className="card-body">
                                    <div className="button-container">
                                        <button
                                            type="submit"
                                            className="active-btn-blue"
                                            disabled={isSubmitting || (srdmIds.length == 0)}
                                        >
                                            Upload
                                        </button>
                                        <button
                                            className="active-btn-white"
                                            type="button"
                                            onClick={handleBoxClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </DialogContent>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        </Dialog>
    );
};

function GenericStyledDropZone(props: any & RequiredDocument) {
    const { handleFileDrop, item } = props;

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxFiles: 10,
        accept: {
            "application/pdf": [".pdf"],
            "image/*": [],
            "application/msg": [".msg"],
            "application/word": [".word"],
        },
        // onDrop: handleFileDrop,
        onDrop: (files) => {
            const newFileName = `${files[0].name}`; // Example: Add 'Modified_' prefix
            const modifiedFile = new File([files[0]], newFileName, {
                type: files[0].type,
                lastModified: files[0].lastModified,
            });

            // Pass modified files to the parent handler
            handleFileDrop(modifiedFile);
        },
    });

    const focusedStyle = {
        borderColor: "#2196f3",
    };

    const acceptStyle = {
        borderColor: "#00e676",
    };

    const rejectStyle = {
        borderColor: "#ff1744",
    };

    const baseStyle = {
        flex: 1,
        display: "flex",
        alignItems: "center",
        padding: "10px",
        borderWidth: 1,
        borderRadius: 8,
        borderColor: "#162E3C80",
        borderStyle: "dashed",
        backgroundColor: "rgba(22, 46, 60, 0.10)",
        color: "#162E3C",
        outline: "none",
        transition: "border .24s ease-in-out",
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <div className="box-style col-lg-12">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} name={"diligentdocument"} multiple />
                <p
                    style={{
                        fontSize: "16px",
                        color: "#162E3C",
                        fontWeight: "600",
                    }}
                >
                    Select File...
                </p>
            </div>
        </div>
    );
}
