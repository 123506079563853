
import { ReactNode, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: 'https://b65c3f18b625fc690e2c1adabd5a54e2@o4508202942660608.ingest.us.sentry.io/4508202944495616',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV || 'production'
});

type ErrorFallbackProps = {
  error: Error;
  resetError: () => void;
};

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetError }) => (
  <div role="alert">
    <h3>An error is occurred.</h3>
    <pre>{error.message}</pre>
    <button onClick={resetError}>Try again</button>
  </div>
);

interface ErrorBoundaryProps {
  children: ReactNode
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const handleError = (error: any, eventId: string) => {
    Sentry.captureException(error)
  }
  useEffect(() => {
    console.log(`*** running on ${process.env.REACT_APP_ENV} ***`)
  }, [])
  const isDevEnv = ['Development', 'Staging'].includes(process.env.REACT_APP_ENV ?? '')
  return isDevEnv ? (
    <Sentry.ErrorBoundary
      onError={(error: unknown, componentStack: string | undefined, eventId: string) => handleError(error, eventId)}
      fallback={({ error, resetError }) => {
        return <ErrorFallback error={error as Error} resetError={resetError} />
      }}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  ) : (
    <>
      {children}
    </>
  )
}
export default ErrorBoundary
