import { useEffect, useRef, useState } from "react";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import React from "react";
import { TaxTransactionReportModel, TaxTransactionReportModel_Resp } from "../models/TaxTransactionReportModel";
import { Box, Stack, width } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Chip, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { writeXlsxFile } from "../helper/utils";
//import { FieldAttributes, useField, useFormikContext } from "formik";
import { Field, FieldArray, FieldAttributes, Form, Formik, useField, useFormik, useFormikContext } from 'formik';
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DiligentSearchList, FMFilters, DiligentTabs } from "../components/Lists/DiligentSearchList";
import Swal from "sweetalert2";
import DiligentMarkCompleted from "./DiligentMarkCompleted";
import moment from "moment";
import { apiClient } from "../helper/api";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";

interface ModelForFilters {
    completedPage?: boolean;
    homeState?: string,
    insuredName?: string,
    policyNumber?: string,
    invoiceNumber?: string,
    needDiligentSearch?: string,
    diligentSearchNotes?: string,
    suspended?: string,
    transactionType?: string
    carrier?: string,
    naic?: string,
    invDate?: string,
    policyEffDate?: string,
    policyExpDate?: string,
    grossPremium?: string,
    client?: string,
    diligentSearchCompleted?: string,
    pageSize?: number;
    pageNumber?: number; 
    field?: string; 
    sort?: string;
    customerIds?: string;
    dateCompleted?: string;
}

export const DiligentSearch = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const initValue: TaxTransactionReportModel_Resp = {
        getOutstandingFilingsReport_Respdata: [],
        totalCount: 0,
        needDiligentSearch: undefined,
        diligentSearchCompleted: undefined,
        dateCompleted: undefined
    }

    const [ReportModule, setReportModule] = useState<TaxTransactionReportModel_Resp>(initValue)
    const [tabValue, setTabValue] = useState<DiligentTabs>(DiligentTabs.OpenDiligent)
    const [selectedRows, setSelectedRows] = useState<TaxTransactionReportModel[]>()
    const [openCompletedDialog, setOpenCompletedDialog] = useState<string>('')
    const [_currentPage, setCurrentPage] = React.useState(1)
    const [_pageSize, setPageSize] = React.useState(50)
    
    const sortRef = useRef<GridSortModel>([])
    const filterRef = useRef<GridFilterModel>()
    const customersRef = useRef<string[]>([])
    const [selectedCustomers, setSelectedCustomers] = React.useState<string[] | string>([]);

    const keysNamesFromXlsx = [
        'HomeState', 'InsuredName', 'PolicyNumber', 'InvoiceNumber',
        'TransactionType', 'Carrier', 'NAIC', 'InvoiceDate', 'PolicyEffDate', 'PolicyExpDate',
        'GrossPremium','Client', 'DiligentSearchToGMAC', 'Suspended', 'NeedDiligentSearch', 'DiligentSearchNotes',
        'DiligentSearchCompleted', 'DateCompleted'
    ]

    const keyNames: (keyof TaxTransactionReportModel)[] = [
        'homeState', 'insuredName', 'policyNumber', 'invoiceNumber',
        'transactionType', 'carrier', 'naic', 'invDate', 'policyEffDate', 'policyExpDate', 
        'grossPremium', 'client', 'diligentSearchToGmac', 'suspended', 'needDiligentSearch', 'diligentSearchNotes',
        'diligentSearchCompleted', 'dateCompleted'
    ]

    const handleOpenCompletedDialog = (status: string) => {
        setOpenCompletedDialog(status);
    };

    const handleCloseCompletedDialog = () => {
        setOpenCompletedDialog('');
    };

    
    const handleSubmitCompletedDialog = (completedDate: string) => {
        setOpenCompletedDialog('');
        setloading(true);
        var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];
        var Ids = customerPolicyIds.join(',')
        apiClient(`/DiligentSearchReport/updateDiligentSearchCompleted?dateCompleted=${completedDate}&ids=${Ids}`, {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }),
        })
        .then((res) => {
            if (!res) return
            setSelectedRows([]);
            Swal.fire({
                title: "Successful!",
                text: "Success",
                icon: "success",
                confirmButtonText: "OK",
            })

            fetchNewData(_pageSize, _currentPage, tabValue);
            setloading(false);
        })
        .finally(() => {
            setloading(false);
        });
        

    };
    
    const getDiligentData = async (pageSize: number, pageNumber: number, selectedTab: DiligentTabs, newFilters?: GridFilterModel, newSort?: GridSortModel) => {
        
        setloading(true);
        const options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
        const selectedCustomerIds = Array.isArray(customersRef.current) ? customersRef.current : [customersRef.current]
        var customerIds = selectedCustomerIds.join(',');
        const initFilterModel: ModelForFilters = {
            //completedPage: 'false'
        }
        initFilterModel.completedPage = selectedTab === DiligentTabs.CompletedDiligent;
        initFilterModel.pageNumber = pageNumber;
        initFilterModel.customerIds = customerIds
        initFilterModel.pageSize = pageSize;
        if (newSort) {
            initFilterModel.field = newSort[0]?.field ?? '';
            initFilterModel.sort = newSort[0]?.sort ?? '';
        }

        initFilterModel.homeState = newFilters?.items.find(m => m.columnField === "homeState")?.value;
        initFilterModel.insuredName = newFilters?.items.find(m => m.columnField === "insuredName")?.value;
        initFilterModel.policyNumber = newFilters?.items.find(m => m.columnField === "policyNumber")?.value;
        initFilterModel.invoiceNumber = newFilters?.items.find(m => m.columnField === "invoiceNumber")?.value;
        initFilterModel.needDiligentSearch = newFilters?.items.find(m => m.columnField === "needDiligentSearch")?.value;
        initFilterModel.diligentSearchNotes = newFilters?.items.find(m => m.columnField === "diligentSearchNotes")?.value;
        initFilterModel.suspended = newFilters?.items.find(m => m.columnField === "suspended")?.value;
        initFilterModel.transactionType = newFilters?.items.find(m => m.columnField === "transactionType")?.value;
        initFilterModel.carrier = newFilters?.items.find(m => m.columnField === "carrier")?.value;
        initFilterModel.naic = newFilters?.items.find(m => m.columnField === "naic")?.value;
        initFilterModel.invDate = newFilters?.items.find(m => m.columnField === "invDate")?.value;
        initFilterModel.policyEffDate = newFilters?.items.find(m => m.columnField === "policyEffDate")?.value;
        initFilterModel.policyExpDate = newFilters?.items.find(m => m.columnField === "policyExpDate")?.value;
        initFilterModel.grossPremium = newFilters?.items.find(m => m.columnField === "grossPremium")?.value;
        initFilterModel.client = newFilters?.items.find(m => m.columnField === "client")?.value;
        initFilterModel.diligentSearchCompleted = newFilters?.items.find(m => m.columnField === "diligentSearchCompleted")?.value;
        initFilterModel.dateCompleted = newFilters?.items.find(m => m.columnField === "dateCompleted")?.value;

        const response = await apiClient(`/DiligentSearchReport/GetDiligentSearchFilteredData`, {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(initFilterModel)
        }).then((responseJson) => {
            if (!responseJson) return
    
            setloading(false);
            if (responseJson.data != null && responseJson.data.totalCount > 0) {
                // setReportModule(responseJson.data);
                return responseJson.data
            } else {
                // setReportModule(initValue);
                return initValue
            }
        }).finally(() => {
            setloading(false);
        });
        return response
    }
    const fetchNewData = async(pageSize: number, pageNumber: number, selectedTab: DiligentTabs, newFilters?: GridFilterModel, newSort?: GridSortModel) => {
        const res = await getDiligentData(pageSize, pageNumber, selectedTab, newFilters, newSort)
        setReportModule(res)
    }

    const handleTabChange = (tab: DiligentTabs) => {
        // setCompletedPage(tab == 2)
        setloading(true);
        setTabValue(tab);
        fetchNewData(50, 1, tab);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const onExportXlsx = (values: TaxTransactionReportModel[]) => {
        setloading(true);
        if (values.length === 0) {
            return
        }
        let filteredKeyNames = keyNames;
        if (tabValue === DiligentTabs.OpenDiligent) {
            filteredKeyNames = keyNames.filter(key => key !== 'dateCompleted' && key !== 'diligentSearchCompleted');
        }
        const items = values.map(item => {
            const update: (string | number | Date | undefined)[] = [];
    
            filteredKeyNames.map((key: keyof TaxTransactionReportModel) => {
                if (key === 'dateCompleted') {
                    update.push(moment.utc(item[key]).format("MM/DD/YYYY"));
                } else {
                    update.push(item[key]);
                }
            });
    
            return update;
        });
        const updates = [
            filteredKeyNames,
            ...items
        ]
        writeXlsxFile(updates)
        setloading(false);
    }
   
    const handleExportXlsxNew = async (val: TaxTransactionReportModel[]) => {
        try {
            setloading(true);

            let arr: TaxTransactionReportModel[] = [];
            let response;
            if (selectedRows && selectedRows.length > 0) {
                arr.push(...selectedRows); // Spread the items only if it's not null
            } else {
                response = await getDiligentData(ReportModule.totalCount, 1, tabValue, filterRef.current, [{field: 'homeState', sort: 'asc'}]);
                if (response) {
                    arr = response.getOutstandingFilingsReport_Respdata
                }
            }

            let values = arr
            if (val && val.length > 0) {
                values = val
            }
            if (values.length <= 0) {
                alert('Please upload a xlsx file')
                return
            }
            onExportXlsx(values);
            Swal.fire({
                title: "Export Successful!",
                text: "Success",
                icon: "success",
                confirmButtonText: "OK",
            });
            setSelectedRows([]);
            setloading(false);
        } catch (error) {
            setloading(false);
            console.error("Error occurred:", error);
        }
    }
    const handleCustomersChange = async (e: string) => {
        var result = selectedCustomers.toLocaleString().split(',').filter((item) => item !== e);
        setSelectedCustomers(result);
        onChangeCustomer(result);
    }

    async function onChangeCustomer(customers: string | string[]) {
        const selectedCustomerIds = Array.isArray(customers) ? customers : [customers]
        customersRef.current = selectedCustomerIds
        fetchNewData(_pageSize, _currentPage, tabValue, filterRef.current, sortRef.current);
    }
    const initialUserdata = { partnersId: 0, customerName: "" };
    const [Customer, setCustomer] = useState([initialUserdata]);
    const fetchCustomer = () => {
        apiClient("/Partners/getPartnersByDiligent", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((responseJson) => {
                if (!responseJson) return
                setCustomer(responseJson.data);
            })
    };

    const FMSelectCustomer: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {

        return (
            <FormControl fullWidth sx={{}}  >
                <InputLabel id="State-Select-Label">Customers</InputLabel>
                <Select
                    // {...field}
                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                    label={"Customers"}

                    className="border-round main-form-2"
                    multiple
                    labelId="State-Select-Label"
                    value={selectedCustomers}
                    IconComponent={ExpandMoreIcon}
                    onChange={(e) => {
                        const selectedValues = e.target.value;
                        setSelectedCustomers(selectedValues);
                        onChangeCustomer(selectedValues);
                    }}
                    renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.toLocaleString().split(',').map((value) => (
                                <Chip

                                    key={value}
                                    label={Customer.find(x => x.partnersId.toString() === value)?.customerName}
                                    onDelete={() =>
                                        handleCustomersChange(value)
                                    }
                                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                />
                            ))}

                        </Stack>
                    )}
                >
                    {Customer.map((Cust, key) =>
                        <MenuItem key={key} value={Cust.partnersId}>  {Cust.customerName} </MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }

    useEffect(() => {
        fetchNewData(50, 1, tabValue);
        fetchCustomer();
    }, [])

    const handleMarkCompleted = () => {
        handleOpenCompletedDialog('true');
    }
    return (
        <><LocalizationProvider dateAdapter={AdapterDayjs}>

            <Box>
                <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                    {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
                </div>
   
            </Box>
          <Box sx={{ width: "100%" }}>
            <Box>
              <div >
                
                  <div className="col-lg-12    ">
                    <div
                      className=" d-flex  "
                      style={{ marginBottom: "28px" }} role="group"
                      aria-label="Basic example"
                    >
                      <React.Fragment>
    
                        <div className="col-6  text-center ">
                          <button type="button"
                            className={`btn-tab btn ${tabValue === DiligentTabs.OpenDiligent ? "btn-Selected" : "btn-primary1"
                              }`}
                            {...a11yProps(1)}
                            onClick={() => handleTabChange(DiligentTabs.OpenDiligent)}
                          >
                            Open Diligent Searches
                          </button>
                        </div>
                        <div className="col-6 text-center">
                            <button
                                type="button"
                                
                                className={`btn-tab btn ${tabValue === DiligentTabs.CompletedDiligent ? "btn-Selected" : "btn-primary"
                                }`}
                                {...a11yProps(2)}
                                onClick={() => handleTabChange(DiligentTabs.CompletedDiligent)}
                            >
                                Completed Diligent Searches
                            </button>
                        </div>
                        </React.Fragment>
                        </div>
                  </div>
                  </div>
                  </Box>
                  <Box>
                  <div className="col-lg-12    ">
                    <div
                      className=" d-flex  "
                      style={{ marginBottom: "28px" }} role="group"
                      aria-label="Basic example"
                    >
                    <Grid
                        container
                        spacing={3} // Adjust the spacing between grid items
                        className="filter-grid"
                        display="flex"
                        justifyContent="flex-end" // Align the buttons to the right
                        alignItems="right"
                    >
                    <React.Fragment>
                        <Grid item xl={2} sm={12} xs={12} minWidth={150}>
                            <FormControl  sx={{ width: '100%' }}>
                            <FMSelectCustomer name="customerIds" />
                            </FormControl>
                        </Grid>
                        {tabValue === DiligentTabs.OpenDiligent && selectedRows?.length !== 0 && selectedRows?.length !== undefined ?
                            <Grid item minWidth={150} className="select-input-border d-flex">
                                
                                <button
                                    onClick={() => handleMarkCompleted()}
                                    className="active-btn-blue"
                                    style={{ width: '100%' }}
                                >
                                    Mark Completed
                                </button>
                            </Grid> : ''
                        }
                        <Grid item xl={2} sm={12} xs={12} minWidth={150} className="select-input-border d-flex">
                            <button
                                onClick={() => handleExportXlsxNew([])}
                                className="active-btn-blue"
                                style={{ width: '100%' }} // Ensure all buttons have equal width
                            >
                                Export
                            </button>
                        </Grid>
                        </React.Fragment>
                    </Grid>
                    </div>
                  </div>
                </Box>       
          </Box>

        </LocalizationProvider>
        {openCompletedDialog && <DiligentMarkCompleted
                open={!!openCompletedDialog}
                handleClose={handleCloseCompletedDialog}
                handleSubmit={handleSubmitCompletedDialog}
                selectedRows={selectedRows}
            />}
            
        <Box
          overflow={"scroll"}
          sx={{
            height: "calc(100% - 60px)",
            width: "100%"
          }}
        >
            <DiligentSearchList
                ReportModuleCL={ReportModule}
                fetchDataMain={fetchNewData}
                tabValue={tabValue}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                _currentPage={_currentPage}
                setCurrentPage={setCurrentPage}
                _pageSize={_pageSize}
                setPageSize={setPageSize}
                sortRef={sortRef}
                filterRef={filterRef}
            />
        </Box>
        </>
    );
}
